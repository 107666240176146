import React, { useState } from "react";
import { Fab, Icon, Popover, MenuItem } from "@material-ui/core";
const ApprovalOptionMenu = ({ menuList, onClickMenu }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = e => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleMenuClick = index => () => {
    onClickMenu(index);
    handleClose();
  };
  return (
    <>
      <Fab
        aria-label="More"
        aria-haspopup="true"
        size="small"
        disableFocusRipple
        color="primary"
        onClick={handleClick}
      >
        <Icon>more_vert</Icon>
      </Fab>
      <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        {menuList.map((text, index) => (
          <MenuItem key={index} onClick={handleMenuClick(index)}>
            {text}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};
export default ApprovalOptionMenu;
