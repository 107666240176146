import { devLog } from "../_helper/commonMethods";
import store from "../_helper/helper.store";
import { formDataConsole, DEV_URL, checkAPIError, generateHTTPRequest } from "../_helper/apiMethods";
import moment from "moment";
import { localCache } from "@prestasi/cache";

export const EmployeeList = () => {
  let formData = new FormData();
  let { user } = store.getState().user;
  formData.append("EmployeeUID", user.empUID);
  formData.append(
    "StartDate",
    moment()
      .startOf("month")
      .add(1, "days")
      .toISOString()
  );
  formData.append("EndDate", moment().toISOString());

  let url = "/api/hrms/attendance/Schedule/Employee/list";
  return generateHTTPRequest(url, formData);
};

export const ApprovalList = (type, fromCache = false) => {
  let formData = new FormData();
  let { user } = store.getState().user;
  let { language: { ipaddress } } = store.getState();
  formData.append("managerUID", user.empUID);
  formData.append("type", type);
  formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
  formData.append('Subscription', user.subscription);
  formDataConsole(formData);
  let url = "/api/hrms/attendance/approve/list";
  if (fromCache) {
    console.log("ApprovalList Fetch from cache: ", url)
    return Promise.resolve(JSON.parse(localCache.getItem("/api/hrms/attendance/approve/list")))
  } else
      console.log("ApprovalList Fetch from network: ", url)

  return fetch(DEV_URL.concat(url), {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + user.token
    }),
    body: formData
  })
  .then((res) => res.json())
  .then((res) => {
      if (res.status !== '1') {
          localCache.setItem("/api/hrms/attendance/approve/list", JSON.stringify(res))
      }
      devLog("// approval list response", res)
      return res;
  })
  .catch(checkAPIError);
};

export const ApproveCheckin = ApproveCheckInJSON => {
  let formData = new FormData();
  formData.append("ApproveCheckInJSON", ApproveCheckInJSON);

  let url = "/api/hrms/attendance/approve/checkin";
  return generateHTTPRequest(url, formData);
};

export const ApproveCheckout = ApproveCheckInJSON => {
  let formData = new FormData();
  formData.append("ApproveCheckOutJSON", ApproveCheckInJSON);

  let url = "/api/hrms/attendance/approve/checkout";
  return generateHTTPRequest(url, formData);
};
