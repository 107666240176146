import React, { Component } from "react";
import { errorNotification } from '../_reducers/reducer.notification';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid, Typography, AppBar, Toolbar } from "@material-ui/core";
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { Link } from "react-router-dom";
import { Leave, Employee } from "../_api";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RefreshIcon from '@material-ui/icons/Refresh';
import { LoadingIndicator } from "../component";
import { toast } from "react-toastify";
import { t } from "../_language";
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from "moment";
import { TRANS_STRING } from "../Const";
import MenuItem from '@material-ui/core/MenuItem';
import { localCache } from "@prestasi/cache";
const { INPUT_REQ } = TRANS_STRING;

class ApplyLeave extends Component {
  static defaultProps = {
    numberOfMonths: 1
  };
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
  }
  componentDidMount() {
    //await LoadingIndicator.instance.showLoader();
    let { user } = this.props;

    Leave.MasterList(user, user.empUID, true).then(result => {
      if (result) {
        this.setState({
          leaveTypeList: result
        })
      }
    })

    Leave.MasterList(user, user.empUID).then(result => {
      if (result.status !== "1") {
        this.setState({
          leaveTypeList: result
        })
      } else {
        toast.error(result.message);
      }
    })

    Employee.Detail(user, null, null, null, true).then(result => {
      if (result) {
        this.setState({
          approveBy: result.directManagerUID
        })
      }
    })

    Employee.Detail(user).then(result => {
      if (result.status !== "1") {
        this.setState({
          approveBy: result.directManagerUID
        })
      }
    })

    //LoadingIndicator.instance.hideLoader();
  }

  getInitialState() {
    return {
      balance: 0,
      days: 0,
      from: undefined,
      to: undefined,
      leaveTypeList: [],
      leaverequest: {
        leaveTypeUID: ''
      }
    };
  }
  getinitialLeave() {
    return {
      leaveTypeUID: '',
      dayAmount: 0,
      startDate: '',
      endDate: '',
      isStartHalfDay: false,
      isEndHalfDay: false,
      startfirst: false,
      startsecond: false,
      endfirst: false,
      endsencond: false,
      Reason: '',
      attachmentFile: '',
      attachment: '',
      addInfo1: '',
      addInfo2: '',
      days: 0,
      approveBy: ''
    }
  }
  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range)
    let diff = moment(range.to, "DD/MM/YYYY").diff(moment(range.from, "DD/MM/YYYY"), 'days') + 1
    this.setState({
      days: diff
    })
  }
  handleResetClick() {
    this.setState(this.getInitialState());
  }
  handleChange = e => {
    let { name, value, type } = e.target;
    if (name === "leaveTypeUID") {
      this.setState({
        balance: this.state.leaveTypeList.find(o => o.leaveTypeUID === value).balance
      })
    }
    if (type === "checkbox") {
      this.setState({
        leaverequest: {
          ...this.state.leaverequest,
          [name]: e.target.checked
        }
      });
    } else {
      this.setState({
        leaverequest: {
          ...this.state.leaverequest,
          [name]: value
        }
      });
    }
  }

  async submitLeave(state) {
    let { user } = this.props;
    if (state.days > state.balance) {
      toast.error(t("validation.errorLeave"))
    } else if (moment(state.from).format('YYYY/MM/DD') > moment(state.to).format('YYYY/MM/DD')) {
      toast.error(t("validation.errorLeaveDate"))
    } else if (state.days === 0) {
      state.days = 1;
    } else {
      let formData = new FormData();
      await LoadingIndicator.instance.showLoader();
      formData.append('days', state.days);
      formData.append('approveBy', state.approveBy);
      formData.append('leaveTypeUID', state.leaverequest.leaveTypeUID);
      formData.append('startDate', moment(state.from).format('YYYY/MM/DD'));
      formData.append('endDate', moment(state.to).format('YYYY/MM/DD'));
      let res = await Leave.Add(formData, user);
      if (res.status !== "1") {
        toast.success(t("validation.leaveSuccess"))

        // data is updated, invalidate existing cache to refresh the view
        localCache.removeItem("/api/hrms/leave/master/list")
        this.props.history.push("/leave")
      } else {
        toast.error(res.message);
      }
    }
    LoadingIndicator.instance.hideLoader();
  }

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <React.Fragment>
        <AppBar position="fixed">
          <Toolbar>
            <ArrowBackIcon style={{ color: '#fff', paddingRight: 15 }} onClick={() => this.props.history.push("/leave")}></ArrowBackIcon>
            <Typography variant="h6">{t("buttons.applyLeave")}</Typography>
            <RefreshIcon style={{ color: '#fff', position: 'absolute', right: 15 }} onClick={() => window.location.reload()}></RefreshIcon>
          </Toolbar>
        </AppBar>
        <div className="RangeExample text-center" style={{ marginTop: 56 }}>
          <DayPicker
            className="Selectable"
            numberOfMonths={this.props.numberOfMonths}
            selectedDays={[from, { from, to }]}
            modifiers={modifiers}
            onDayClick={this.handleDayClick}
          />
        </div>
        <Grid item md={12}>
          <div className="container leave">
            <ValidatorForm>
              <div className="col-md-12">
                <br />
                <div className="text-center">
                  <span className="balance">{t("buttons.leaveBalance")}: {this.state.balance}</span>
                </div>
                <br />
                <SelectValidator
                  name="leaveTypeUID"
                  onChange={this.handleChange}
                  label={t("buttons.leaveType")}
                  options={{ placeholder: "Choose" }}
                  errorMessages={[t(INPUT_REQ)]}
                  value={this.state.leaverequest.leaveTypeUID}
                >
                  {this.state.leaveTypeList.map((item, key) => (
                    <MenuItem key={key} value={item.leaveTypeUID}>{item.leaveName}</MenuItem>
                  ))}
                </SelectValidator>
              </div>
              <div className="col-md-6">
                <TextValidator
                  key="startDate"
                  value={moment(from).format("DD/MM/YYYY")}
                  name="startDate"
                  //onChange={this.onChange}
                  validators={["required"]}
                  label={t("buttons.startDate")}
                  errorMessages={[t(INPUT_REQ)]}
                  margin="normal"
                  style={{ fontSize: 12 }}
                />
              </div>
              <div className="col-md-6">
                <TextValidator
                  key="endDate"
                  value={moment(to).format("DD/MM/YYYY")}
                  name="endDate"
                  //onChange={this.onChange}
                  validators={["required"]}
                  label={t("buttons.endDate")}
                  errorMessages={[t(INPUT_REQ)]}
                  margin="normal"
                  style={{ fontSize: 12 }}
                />
              </div>
              <div className="col-md-12 text-center">
                <Link className="submitLeaveBtn" onClick={() => this.submitLeave(this.state)}>{t("buttons.submitRequest")}</Link>
                <Link className="cancelBtn" to="/leave">{t("buttons.cancel")}</Link>
              </div>
            </ValidatorForm>
          </div>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  errorNotification
}, dispatch)
const mapStateToProps = state => ({
  user: state.user.user,
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplyLeave);