import React, { useEffect, useState } from "react";
import { Footer } from "../component";
import { Grid, Typography, AppBar, Toolbar } from "@material-ui/core";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Link } from "react-router-dom";
import { LoginAPI } from "../_api";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RefreshIcon from '@material-ui/icons/Refresh';
import { LoadingIndicator } from "../component";
import { toast } from "react-toastify";
import { useSelector } from 'react-redux'
import { t } from "../_language";
import { TRANS_STRING } from "../Const";
const { INPUT_REQ, VALIDATION_STRING } = TRANS_STRING;

function Password(props) {
  const [myState, setMyState] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  })

  //get state from store
  const user = useSelector(state => state.user).user

  const onChangePassword = async e => {
    
    if (e.oldPassword === e.newPassword) {
      toast.error(t("validation.oldPwd"));
    } else {
      let formData = new FormData();
      formData.append('empUID', user.empUID);
      formData.append('oldPassword', e.oldPassword);
      formData.append('newPassword', e.newPassword);
      await LoadingIndicator.instance.showLoader();
      let response = await LoginAPI.changePassword(user, formData);
      if (response.status !== "1") {      
        toast.success(t("validation.passwordSuccess"));
        props.history.push("/")
      } else {
        toast.error(response.message);
      }
    }    
    LoadingIndicator.instance.hideLoader();
  }

  const handleChange = e => {
    let { name, value } = e.target;
    setMyState(state => ({
      ...state,
      [name]: value
    }))
  }

  useEffect(() => {
    async function componentDidMount() {
      await LoadingIndicator.instance.showLoader();
        
      LoadingIndicator.instance.hideLoader();      
    }
    
    componentDidMount();
  }, [user])

  let { oldPassword, newPassword, confirmPassword } = myState;
  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <ArrowBackIcon style={{ color: '#fff', paddingRight: 15 }} onClick={() => props.history.push("/home")}></ArrowBackIcon>
          <Typography variant="h6">{t("buttons.changePassword")}</Typography>
          <RefreshIcon style={{ color: '#fff', position: 'absolute', right: 15 }} onClick={() => window.location.reload()}></RefreshIcon>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: 56 }}>
        <Grid item md={12} style={{ textAlign: 'left', width: '100%', padding: 15, zIndex: 99 }}>
          <ValidatorForm onSubmit={onChangePassword}>
            <TextValidator
              margin="normal"
              name="oldPassword"
              type="password"
              onChange={handleChange}
              label={t("buttons.oldPassword")}
              value={oldPassword}
              validators={["required"]}
              errorMessages={[t(INPUT_REQ)]}
            />
            <TextValidator
              margin="normal"
              name="newPassword"
              type="password"
              onChange={handleChange}
              label={t("buttons.newPassword")}
              value={newPassword}
              validators={["required", "isoldPasswordMatch"]}
              errorMessages={[t(INPUT_REQ), t("validation.oldPwd")]}
            />
            <TextValidator
              margin="normal"
              type="password"
              name="confirmPassword"
              onChange={handleChange}
              label={t("buttons.confirmPassword")}
              value={confirmPassword}
              validators={["required", `matchRegexp:^${newPassword}`]}
              errorMessages={[
                t(INPUT_REQ),
                t(VALIDATION_STRING + "confirmPassword")
              ]}
            />
            <div className="col-md-12 text-center">
              <Link className="submitLeaveBtn" onClick={() => onChangePassword(myState)}>{t("buttons.submitRequest")}</Link>
              <Link className="cancelBtn" to="/profile">{t("buttons.cancel")}</Link>
            </div>
          </ValidatorForm>
        </Grid>        
      </div>
      <div className="clearfix"></div>
      <Footer active="profile" />
    </React.Fragment>
  );
}

export default Password;