import { Badge, withStyles } from "@material-ui/core";

const TabBadge = withStyles(theme => ({
  badge: {
    top: "50%",
    right: -15,
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[900]
    }`
  }
}))(Badge);

export default TabBadge;
