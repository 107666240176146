import { formDataConsole, DEV_URL, checkAPIError, GetClientIP, SESS_STORAGE_KEY } from "../_helper/apiMethods";
import { devLog } from "../_helper/commonMethods";
import { setIP } from '../_reducers/reducer.language';
import store from '../_helper/helper.store';
import { localCache } from "@prestasi/cache";
//import { nofetch as fetchJSON } from "@prestasi/nofetch";

export const Email = (formData) => {
  return GetClientIP().then(function (result) {
    store.dispatch(setIP(result));
    formData.append('DeviceIP', result);
    //formDataConsole(formData);
    return fetch(DEV_URL.concat("/api/login/email"), {
      method: "POST",
      body: formData
    })
      .then((res) => res.json())
      .then((res) => {
        devLog("// email response", res)
        localCache.removeItem("/api/hrms/employee/detail");
        localCache.removeItem("/api/hrms/employee/detail/family");
        localCache.removeItem("/api/hrms/employee/detail/address");
        localCache.removeItem("/api/hrms/attendance/schedule/employee/list");
        localCache.removeItem("/api/hrms/rms/master/list");
        localCache.removeItem("/api/hrms/rms/applied/list");
        localCache.removeItem("/api/hrms/attendance/checkcheckin");
        localCache.removeItem("/api/hrms/leave/master/list");
        localCache.removeItem("/api/hrms/leave/request/list");
        localCache.removeItem("/api/hrms/attendance/approve/list");
        localCache.removeItem(SESS_STORAGE_KEY);
        return res;
      })
      .catch(checkAPIError);
  })
}

export const Password = async (state, formData, ipAddress) => {
  if (!ipAddress)
    ipAddress = await GetClientIP()
  store.dispatch(setIP(ipAddress));
  formData.append('DeviceIP', ipAddress);
  //let { language: { ipaddress } } = store.getState();
  formData.append('UserPassword', state.password);
  formData.append('Subscription', state.subscription);
  formData.append("UserID", state.userID);
  //formData.append('DeviceIP', ipaddress);
  //formDataConsole(formData);
  //return fetch(DEV_URL.concat("/api/login/passmin"), {
  return fetch(DEV_URL.concat("/api/login/authenmin"), {
    method: "POST",
    body: formData
  })
    .then((res) => res.json())
    .then((res) => {
      devLog("// login response", res)
      localCache.setItem("ipaddress", ipAddress);
      localCache.removeItem("/api/hrms/employee/detail");
      localCache.removeItem("/api/hrms/employee/detail/family");
      localCache.removeItem("/api/hrms/employee/detail/address");
      localCache.removeItem("/api/hrms/attendance/schedule/employee/list");
      localCache.removeItem("/api/hrms/rms/master/list");
      localCache.removeItem("/api/hrms/rms/applied/list");
      localCache.removeItem("/api/hrms/attendance/checkcheckin");
      localCache.removeItem("/api/hrms/leave/master/list");
      localCache.removeItem("/api/hrms/leave/request/list");
      localCache.removeItem(SESS_STORAGE_KEY);
      return res;
    })
    .catch(checkAPIError);
  //})
}

export const Remove = (user) => {
  let { language: { ipaddress } } = store.getState();
  let formData = new FormData();
  formData.append('Subscription', user.subscription);
  formData.append("ActionBy", user.empUID);
  formData.append('DeviceIP', ipaddress);
  formDataConsole(formData);
  return fetch(DEV_URL.concat("/api/login/remove"), {
    method: "POST",
    headers: new Headers({
      'Authorization': 'Bearer ' + user.token
    }),
    body: formData
  })
    .then((res) => res.json())
    .then((res) => {
      devLog("// remove response", res)
      localCache.removeItem("ipaddress");
      localCache.removeItem("/api/hrms/employee/detail");
      localCache.removeItem("/api/hrms/employee/detail/family");
      localCache.removeItem("/api/hrms/employee/detail/address");
      localCache.removeItem("/api/hrms/attendance/schedule/employee/list");
      localCache.removeItem("/api/hrms/rms/master/list");
      localCache.removeItem("/api/hrms/rms/applied/list");
      localCache.removeItem("/api/hrms/attendance/checkcheckin");
      localCache.removeItem("/api/hrms/leave/master/list");
      localCache.removeItem("/api/hrms/leave/request/list");
      localCache.removeItem(SESS_STORAGE_KEY);
      return res;
    })
    .catch(checkAPIError);
}

export const changePassword = (user, formData) => {
  let { language: { ipaddress } } = store.getState();
  formData.append('Subscription', user.subscription);
  formData.append("ActionBy", user.empUID);
  formData.append('DeviceIP', ipaddress);
  formDataConsole(formData);
  return fetch(DEV_URL.concat("/api/login/change"), {
    method: "POST",
    headers: new Headers({
      'Authorization': 'Bearer ' + user.token
    }),
    body: formData
  })
    .then((res) => res.json())
    .then((res) => {
      devLog("// change password response", res)
      return res;
    })
    .catch(checkAPIError);
}

export const Forget = (user, formData) => {
  return GetClientIP().then(function (result) {
    store.dispatch(setIP(result));
    formData.append('empUID', user.empUID);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', result);
    formDataConsole(formData);
    return fetch(DEV_URL.concat("/api/login/forget"), {
      method: "POST",
      body: formData
    })
      .then((res) => res.json())
      .then((res) => {
        devLog("// forget response", res)
        return res;
      })
      .catch(checkAPIError);
  })
}