import React, { useEffect, useState } from "react";
import { Grid, Typography, AppBar, Toolbar } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from "moment";
import { Checkinout } from "../_api";
import { t } from "../_language";
import { LoadingIndicator } from "../component";
import { useSelector } from 'react-redux'
import GoogleMapReact from 'google-map-react';

function AttendanceDetail(props) {
  const [myState, setMyState] = useState(
    {
      now: moment(),
      photoUrl: '',
      CheckInDetail: {
        checkIn: ''
      },
      CheckOutDetail: {
        checkOut: ''
      },
      dateselected: moment(),
      checkinoutdetail: {},
      zoom: 11,
      zoom2: 11,
      center: {
        lat: '',
        lng: ''
      },
      center2: {
        lat: '',
        lng: ''
      },
    }
  )
  const user = useSelector(state => state.user).user

  const AnyReactComponent = ({ text }) => <div><i className="fa fa-map-marker" aria-hidden="true" style={{ color: 'red', fontSize: 'x-large' }}></i>{text}</div>;

  useEffect(() => {

    function componentDidMount() {
      let { history: { location } } = props;
      
      LoadingIndicator.instance.showLoader();
      if (location.state.item.checkOut !== null) {
        Checkinout.CheckAtt(user, location.state.item.uid)
        .then(result => {
          if (result.status !== "1") {            
            setMyState(state => ({
              ...state,
              checkinoutdetail: result,
              center: {
                lat: parseFloat(result.checkInLatitude),
                lng: parseFloat(result.checkInLongitude)
              },
              center2: {
                lat: parseFloat(result.checkOutLatitude),
                lng: parseFloat(result.checkOutLongitude)
              }
            }))
          }
        })
      } else {
        Checkinout.CheckChekin(user)
        .then(result => {
          if (result.status !== "1") {
            setMyState(state => ({
              ...state,
              checkinoutdetail: {
                ...result,
                checkInURL: location.state.item.checkInurl
              },
              center: {
                lat: parseFloat(result.checkInLatitude),
                lng: parseFloat(result.checkInLongitude)
              }
            }))
          }
        }) 
      }
      

      
      let t0 = performance.now()

      LoadingIndicator.instance.hideLoader();
      console.log("loading data in ", performance.now() - t0, "ms")
    }

    componentDidMount();
  },[user, props])

  let defaultimage = "/assets/images/user.png";
  let { checkinoutdetail, center, zoom, center2, zoom2 } = myState;
  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <ArrowBackIcon style={{ color: '#fff', paddingRight: 15 }} onClick={() => props.history.push("/attendance")}></ArrowBackIcon>
          <Typography variant="h6">{t("buttons.attendancedetail")}</Typography>
          <RefreshIcon style={{ color: '#fff', position: 'absolute', right: 15 }} onClick={() => window.location.reload()}></RefreshIcon>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: 80 }}>
        <Grid
          container
          spacing={3}
        >
          <div className="history">
            <p className="text-large text-bold" style={{ paddingLeft: 15 }}>{t("buttons.attendanceDate") + ": " + moment(checkinoutdetail.attendanceDate).format("DD-MM-YYYY")}</p>
            <div className="historydetail">
              <Grid container spacing={16} className="employeeData">
                <Grid item xs={4}>
                  <img
                    alt='checkinuser'
                    style={{ width: '100%', height: 'auto', display: 'inline-block', }}
                    src={checkinoutdetail.checkInURL || defaultimage} />
                </Grid>
                <Grid item xs={1}>&nbsp;</Grid>
                <Grid item xs={7}>
                  <div><span className="in">IN</span> <span>{checkinoutdetail.checkIn ? moment(checkinoutdetail.checkIn).format("HH:mm") : '--'}</span></div>
                  <div style={{ height: '30vh', width: '100%', paddingTop: '20px', paddingBottom: '20px' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: 'AIzaSyApMeAGsdAfi9Cf9-JGLZtMPKG0Po3vfUw' }}
                      center={center}
                      defaultZoom={zoom}
                    >
                      <AnyReactComponent
                        lat={checkinoutdetail.checkInLatitude}
                        lng={checkinoutdetail.checkInLongitude}
                        text={''}
                      />
                    </GoogleMapReact>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="historydetail">
              <Grid container spacing={16} className="employeeData">
                <Grid item xs={4}>
                  <img
                    alt='checkoutuser'
                    style={{ width: '100%', height: 'auto', display: 'inline-block', }}
                    src={checkinoutdetail.checkOutURL || defaultimage} />
                </Grid>
                <Grid item xs={1}>&nbsp;</Grid>
                <Grid item xs={7}>
                  <div><span className="out">OUT</span> <span>{checkinoutdetail.checkOutURL ? moment(checkinoutdetail.checkOut).format("HH:mm") : '--'}</span></div>
                  {checkinoutdetail.checkOutURL ?
                    <div style={{ height: '30vh', width: '100%', paddingTop: '20px', paddingBottom: '20px' }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyApMeAGsdAfi9Cf9-JGLZtMPKG0Po3vfUw' }}
                        center={center2}
                        defaultZoom={zoom2}
                      >
                        <AnyReactComponent
                          lat={checkinoutdetail.checkOutLatitude}
                          lng={checkinoutdetail.checkOutLongitude}
                          text={''}
                        />
                      </GoogleMapReact>
                    </div> : ""}
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default AttendanceDetail
