import { IS_DEVEOPMENT } from "../Const";
import { toast } from "react-toastify";
import store from "./helper.store";
import { devLog } from "./commonMethods";
import { nofetch as fetchJSON } from '@prestasi/nofetch'
import { localCache } from '@prestasi/cache'

var encr = require("crypto-js");
//var ip = require("ip");
var moment = require('moment');
export const DEV_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://sarvamprodapi.azurewebsites.net';
export const SESS_STORAGE_KEY = '@sarvam:data';
export const WHITE_TEXT_COLOR = ['red', 'green', 'blue', 'orange'];

export const formDataConsole = (formData, url) => {
  if (!IS_DEVEOPMENT) {
    console.log("// API Request Body Data", url ? url : "");
    for (var pair of formData.entries()) {
      console.log(pair[0] + " : " + pair[1]);
    }
    console.log("// End Request");
  }
};

export const getSignature = sig => {
  var wordArray = encr.SHA1(sig);
  var words = wordArray.words;
  var sigBytes = wordArray.sigBytes;
  var hexChars = [];
  for (var i = 0; i < sigBytes; i++) {
    var bite = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
    hexChars.push((bite >>> 4).toString(16));
    hexChars.push((bite & 0x0f).toString(16));
  }
  return hexChars.join("").toUpperCase();
};

export const checkNetworkError = response => {
  if (!response.ok || response.status !== 200) {
    throw Error("Network Error");
  }
  return response.json();
};

export const checkAPIResError = (url, shouldShowToast) => response => {
  devLog(`Response +${url}`, response);
  if (response.status !== "1") {
    return response;
  } else {
    if (shouldShowToast) {
      toast.error(response.message || "Something Went Wrong");
    }
    return null;
  }
};

export const catchAPIError = error => {
  devLog("Error", error);
  toast.error(error.message || "Something Went Wrong");
  return null;
};

export const resetNullFromResponse = response => {
  let newObject = {};
  const mapFunciton = key => {
    if (!response[key]) {
      newObject = {
        ...newObject,
        [key]: ""
      };
    } else
      newObject = {
        ...newObject,
        [key]: response[key]
      };
  };
  Object.keys(response).map(mapFunciton);
  return newObject;
};

export const generateRequiredFormDataFields = formData => {
  let { user } = store.getState().user;
  let { language: { ipaddress } } = store.getState();
  formData.append("Subscription", user.subscription);
  formData.append("ActionBy", user.empUID);
  formData.append("DeviceIP", localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);

  formDataConsole(formData);
  return formData;
};

/** 
* Generate Fetch Requrest, Handles Response.
* if api has error resolves to null
* otherwise resolves to response.
* Error message are toasted out automatically.
@param shouldShowToast true
*/
export const generateHTTPRequest = (url, formData, shouldShowToast = true) => {
  let { user } = store.getState().user;

  devLog(`// Request URL ${url}`);
  generateRequiredFormDataFields(formData);

  return fetch(DEV_URL.concat(url), {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + user.token
    }),
    body: formData
  })
    .then(checkNetworkError)
    .then(checkAPIResError(url, shouldShowToast))
    .catch(catchUnknownError);
};

export const GetClientIP = async (fromCache = false) => {
  let url = 'https://sarvamlogtrigger.azurewebsites.net/api/GetRemoteIPAddress?format=json';
  if (fromCache) {
    console.log("from cache GetClientIP")
    return Promise.resolve(localCache.getItem("ipaddress"))
  }
  devLog('url', url);
  console.log("from GetClientIP")
  var data = await fetchJSON(url);
  if (!data)
    return null
  localCache.setItem("ipaddress", data.ip)
  return data.ip;
}

export const checkAPIError = (err) => {
  return { status: '1', message: err.message }
}

export const catchUnknownError = err => {
  toast.error(err.message);
  return null;
};

export const getCalenderView = (cdate, sys) => {
  if (sys) {
    if (cdate && cdate.length === 10) {
      return `${cdate.slice(6)}/${cdate.slice(3, 5)}/${cdate.slice(0, 2)}`;
    } else {
      return cdate
    }
  } else {
    if (cdate && cdate.length === 10) {
      return `${cdate.slice(6)}/${cdate.slice(3, 5)}/${cdate.slice(0, 2)}`;
    } else {
      return moment().format('YYYY/MM/DD')
    }
  }
}

export const formatDate = (date) => {
  if (date) {
    let fdate = new Date(date);
    let y = fdate.getFullYear();
    let m = fdate.getMonth() + 1;
    m = m <= 9 ? '0' + m : m;
    let d = fdate.getDate() <= 9 ? "0" + fdate.getDate() : fdate.getDate();
    let ndate = d + '/' + m + '/' + y;
    return ndate;
  } else {
    return ''
  }

}

export const handleFormErrors = (errors) => {
  let err = errors[0];
  if (err.Select2) {
    err.Select2.el.focus();
  } else if (err.input) {
    err.input.focus();
  } else if (err.textarea) {
    err.textarea.focus();
  }
}