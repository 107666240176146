import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Grid, Select, MenuItem } from "@material-ui/core";
import { setDefaultTranslations, setDefaultLanguage } from "../_language";
import en from "../_language/en.json";
import id from "../_language/id.json";
import { localCache } from "@prestasi/cache";
import { LoadingIndicator2 } from "../component";

function Header(props){
  const [myState, setMyState] = useState({
    lang: "id",
    imgLogoUrl: localCache.getItem("logowhite")
  })
  const changeLanguage = (e) => {
    let { value } = e.target;
    setDefaultTranslations({ id, en });
    localCache.setItem("language",value); 
      setMyState(state => ({
        ...state,
        lang: value
      }))
    setDefaultLanguage(value);  
  } 

  const toDataURL = (e) => {    
    let img = e.target
    var canvas = document.createElement('canvas');
    canvas.width = img.naturalWidth; // or 'width' if you want a special/scaled size
    canvas.height = img.naturalHeight; // or 'height' if you want a special/scaled size

    canvas.getContext('2d').drawImage(img, 0, 0);

    // ... or get as Data URI
    let base64Img = canvas.toDataURL('image/png')
    localCache.setItem(img.name,base64Img)
  }

  useEffect(() => {
    function componentDidMount() {
      LoadingIndicator2.instance.showLoader();    
      
      if (localCache.getItem("language")) {
        setMyState(state => ({
          ...state,
          lang: localCache.getItem("language")
        }))
        setDefaultLanguage(localCache.getItem("language"));      
      }    
       
      LoadingIndicator2.instance.hideLoader()
    }
    componentDidMount();    
  },[myState.lang]) // array of dependencies, only call useEffect if this variable changed in value

  let { lang, imgLogoUrl } = myState;
  return (
    <AppBar
      position="fixed"
      elevation={4}
      style={{ background: '#622bd9', boxShadow: 'none', padding: '15px', zIndex: '100' }}
    >
      <Toolbar>
        <Grid item md={12} style={{ textAlign: 'center', width: '100%' }}>
          <Link to={{ pathname: "/home" }}>
            <img
              src={imgLogoUrl || "/assets/images/logowhite.png"}
              //src={"/assets/images/logowhite.png"}
              style={{ height: 50 }}
              alt="sarvam"
              name="logowhite"
              onLoad={e => toDataURL(e)}
            />            
          </Link>
          <div className="selLanguage">            
            <Select id="select" value={lang} onChange={e => changeLanguage(e)}>
              <MenuItem value="en">EN</MenuItem>
              <MenuItem value="id">ID</MenuItem>
            </Select>
          </div>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
export default Header;
