import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedin } from '../_reducers/reducer.user';
import { localCache } from "@prestasi/cache";
//import store from "../_helper/helper.store";
import moment from "moment";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      let logged = isLoggedin();
      if (localCache.getItem("user")) {
        logged = true;
        let userParse = JSON.parse(localCache.getItem("user"));
        let now = moment().format("YYYY-MM-DD HH:mm:ss");
        let expiryToken = moment(userParse.tokenExpiryDate).format("YYYY-MM-DD HH:mm:ss");
        if(now > expiryToken)
        {
          logged = false;
          localCache.removeItem("user");
        }
      }

      if (logged) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      }
    }}
  />
);
export default PrivateRoute;
