import React, { useEffect, useState } from "react";
import { Grid, Tabs, Tab, Fab, Divider, Typography, AppBar, Toolbar, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from "moment";
import { Checkinout, AttendenceAPI } from "../_api";
import { toast } from "react-toastify";
import { t } from "../_language";
import { LoadingIndicator, ApprovalOptionMenu } from "../component";
import { formatDate } from '../_helper/apiMethods';
import { useSelector } from 'react-redux';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import GoogleMapReact from 'google-map-react';
const Compress = require('compress.js')
//import sessionCache from '../_helper/utility/sessionCache'

function Attendance(props) {
  const [myState, setMyState] = useState(
    {
      photoUrl: '',
      latitude: '-6.2301495',
      longitude: '106.8241209',
      CheckInDetail: {
        checkIn: ''
      },
      CheckOutDetail: {
        checkOut: ''
      },
      openModal: false,
      overTimeMin: '',
      dateselected: moment(),
      requestList: [],
      employeelist: [],
      ischeckin: false,
      ischeckout: false,
      checkinApprovalList: [],
      checkoutApprovalList: [],
      zoom: 11,
      center: {
        lat: '',
        lng: ''
      }
    }
  )
  let { isServiceManager } = props.location.state || {};
  const [now, setNow] = useState(moment())
  const [selectedTab, setSelectedTab] = useState(0);
  const user = useSelector(state => state.user).user
  const compress = new Compress()

  /*const startTimer = () => {
    setInterval(() => {
      setMyState(state => ({
        ...state,
        now: moment()
      }))
    }, 1000)
  }*/

  const displayLocationInfo = (position) => {
    setMyState(state => ({
      ...state,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      center: {
        lat: parseFloat(position.coords.latitude),
        lng: parseFloat(position.coords.longitude)
      }
    }))
  }

  const handleClose = () => {
    setMyState(state => ({
      ...state,
      open: false
    }))
  } 

  const showDetail = (item) => {
    props.history.push(
      {
        pathname: `/attendancedetail`,
        state: {
          item
        }
      })
  }

  const submitCheckin = (e) => {
    document.getElementById('profilePhoto').click();    
  }

  const submitCheckout = (e) => {
    document.getElementById('profilePhoto2').click();
  }

  const checkinNoshift = async (event) => {
    //let reader = new FileReader();
    let file = event.target.files[0];
    if (myState.latitude === 0 && myState.longitude === 0) {
      //toast.error(t("validation.noLocation"));
      //return;
      setMyState(state => ({
        ...state,
        latitude: '',
        longitude: ''
      }))
    }
    /*let fileBlob = new Blob([file], { type: "image/jpeg" })
    console.log(fileBlob);*/
   
    if(file)
    {
      var data = await compress.compress([file], {
        size: 1, // the max size in MB, defaults to 2MB
        quality: 0.75, // the quality of the image, max is 1,
        maxWidth: 1024, // the max width of the output image, defaults to 1920px
        maxHeight: 1024, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
      });
      // returns an array of compressed images
      if( data[0])
      {
        var fileuploadtemp = Compress.convertBase64ToFile(data[0].data, data[0].ext);
        let { CheckInDetail } = myState;
        //let reader = new FileReader();
        let uid = null;
        const formData = new FormData();
        
        await LoadingIndicator.instance.showLoader();
        formData.append('FileCheckIn', fileuploadtemp);
        formData.append('CheckInNotes', 'From Mobile Web');
        formData.append('Latitude', myState.latitude.toString().replace(",","."));
        formData.append('Longitude', myState.longitude.toString().replace(",","."));
        //formData.append('CheckIn', attendanceDate);
        if (CheckInDetail.uid !== null && CheckInDetail.uid !== undefined) {
            uid = CheckInDetail.uid;
        } else {
            uid = null;
        }
        let res = await Checkinout.Checkin(formData, user, uid);
        if (res.status !== "1") {
          refreshData(); // refresh the session storage
          toast.success(t("validation.checkinSuccess"))
        } else {
          toast.error(res.message)
        }
      }
    }
   
    /*
    
    if (file) {
      let { CheckInDetail } = myState;
      //let reader = new FileReader();
      let uid = null;
      //let attendanceDate = now.format("YYYY-MM-DD HH:mm:ss");
      let file = event.target.files[0];
      const formData = new FormData();
      
      await LoadingIndicator.instance.showLoader();
      formData.append('FileCheckIn', fileBlob);
      formData.append('CheckInNotes', 'From Mobile Web');
      formData.append('CheckInLatitude', myState.latitude);
      formData.append('CheckInLongitude', myState.longitude);
      //formData.append('CheckIn', attendanceDate);
      if (CheckInDetail.uid !== null && CheckInDetail.uid !== undefined) {
          uid = CheckInDetail.uid;
      } else {
          uid = null;
      }
      let res = await Checkinout.Checkin(formData, user, uid);
      if (res.status !== "1") {
        refreshData(); // refresh the session storage
        toast.success(t("validation.checkinSuccess"))
      } else {
        toast.error(res.message)
      }
    }
    */
    LoadingIndicator.instance.hideLoader();
  }

  const checkoutNoshift = async (event) => {
    //let reader = new FileReader();
    let file = event.target.files[0];
    if (myState.latitude === 0 && myState.longitude === 0) {
      //toast.error(t("validation.noLocation"));
      //return;
      setMyState(state => ({
        ...state,
        latitude: '',
        longitude: ''
      }))
    }
    if(file)
    {
      var data = await compress.compress([file], {
        size: 1, // the max size in MB, defaults to 2MB
        quality: 0.75, // the quality of the image, max is 1,
        maxWidth: 1024, // the max width of the output image, defaults to 1920px
        maxHeight: 1024, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
      });
      // returns an array of compressed images
      if(data[0])
      {
        var fileuploadtemp = Compress.convertBase64ToFile(data[0].data, data[0].ext);        
        let { CheckInDetail, CheckOutDetail } = myState;
        //let reader = new FileReader();
        const formData = new FormData();
        await LoadingIndicator.instance.showLoader();
        formData.append('FileCheckout', fileuploadtemp);
        formData.append('OverTimeMin', CheckOutDetail.overTimeMin > 120 ? "120" : CheckOutDetail.overTimeMin);
        formData.append('CheckOutNotes', 'From Mobile Web');
        formData.append('Latitude', myState.latitude.toString().replace(",","."));
        formData.append('Longitude', myState.longitude.toString().replace(",","."));
        //formData.append('CheckOut', attendanceDate);
        let res = await Checkinout.Checkout(formData, user, CheckInDetail.uid);
        if (res.status !== "1") {
          refreshData(); //invalidate the cache
          toast.success(t("validation.checkoutSuccess"))
          window.location.reload();
        } else {
          toast.error(res.message)
        }
        setMyState(state => ({
          ...state,
          load: false
        }))
      }
    }
    /*if (file) {
      let { CheckInDetail } = myState;
      //let attendanceDate = now.format("YYYY-MM-DD HH:mm:ss");
      //let reader = new FileReader();
      let file = event.target.files[0];
      const formData = new FormData();
      let fileBlob = new Blob([file], { type: "image/jpeg" })
      await LoadingIndicator.instance.showLoader();
      formData.append('FileCheckout', fileBlob);
      formData.append('OverTimeMin', myState.overTimeMin === "" ? "0" : myState.overTimeMin);
      formData.append('CheckOutNotes', 'From Mobile Web');
      formData.append('CheckOutLatitude', myState.latitude);
      formData.append('CheckOutLongitude', myState.longitude);
      //formData.append('CheckOut', attendanceDate);
      let res = await Checkinout.Checkout(formData, user, CheckInDetail.attendanceUID);
      if (res.status !== "1") {
        refreshData(); //invalidate the cache
        toast.success(t("validation.checkoutSuccess"))
      } else {
        toast.error(res.message)
      }
      setMyState(state => ({
        ...state,
        load: false
      }))
    }*/
    LoadingIndicator.instance.hideLoader();
  }

  const loadImage2 = async (event) => {
    //let reader = new FileReader();
    let file = event.target.files[0];
    if (myState.latitude === 0 && myState.longitude === 0) {
      setMyState(state => ({
        ...state,
        latitude: '',
        longitude: ''
      }))
    }
    if (file) {
      let { CheckOutDetail } = myState;
      let file = event.target.files[0];
      const formData = new FormData();
      let fileBlob = new Blob([file], { type: "image/jpeg" })
      await LoadingIndicator.instance.showLoader();
      formData.append('FileCheckout', fileBlob);
      formData.append('OverTimeMin', myState.overTimeMin === "" ? "0" : myState.overTimeMin);
      formData.append('CheckOutNotes', 'From Mobile Web');
      formData.append('Latitude', myState.latitude);
      formData.append('Longitude', myState.longitude);
      let res = await Checkinout.Checkout(formData, user, CheckOutDetail.uid);
      if (res.status === "0") {
        refreshData();
        toast.success(t("validation.checkoutSuccess"))
      } else {
        toast.error(res.message)
      }
      setMyState(state => ({
        ...state,
        load: false
      }))
    }
    LoadingIndicator.instance.hideLoader();
  }

  const onChangeTab = (e, value) => {
    setSelectedTab(value);
  };

  async function refreshData() {
    //noshift
    let res = await Checkinout.CheckChekin(user);
    if (res.status !== "1") {
      setMyState(state => ({
        ...state,
        CheckInDetail: {
          checkIn: res.checkIn,
          attendanceDate: res.attendanceDate,
          uid: res.attendanceUID
        },
        ischeckin: true
      }))
    } else {
      setMyState(state => ({
        ...state,
        CheckInDetail: {
          checkIn: ''
        },
        ischeckin: false
      }))
    }

    let startDate = moment().subtract(30, 'day').format("YYYY-MM-DD");
    let endDate = moment().add(0, 'day').format("YYYY-MM-DD");

    Checkinout.AttendanceNoshift(user, startDate, endDate, true) // from cache
      .then(result => {
        if (result) {
          setMyState(state => ({
            ...state,
            employeelist: result
          }))
          for (const item of result) {
            if (moment(item.checkOut).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD") && moment(item.checkIn).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD")) {
              setMyState(state => ({
                ...state,
                ischeckout: true
              }))
            }
          }
        }
      })

    Checkinout.AttendanceNoshift(user, startDate, endDate) // from network
      .then(result => {
        if (result.status !== '1') {
          setMyState(state => ({
            ...state,
            employeelist: result
          }))
          for (const item of result) {
            if (moment(item.checkOut).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD") && moment(item.checkIn).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD")) {
              setMyState(state => ({
                ...state,
                ischeckout: true
              }))
            }
          }
        }
      })
  }

  const getLocDateFromArray = (res) => {    
    let data = [...res];
    data.sort(function (a, b) { return moment(a.attendanceDate) - moment(b.attendanceDate) }).map((item, i) => {
      data[i] = {
        ...item,
        attendanceDate: item.attendanceDate ? formatDate(item.attendanceDate) : '',
        shiftStartTime: item.shiftStartTime ? formatDate(item.shiftStartTime) : '',
        shiftEndTime: item.shiftEndTime ? formatDate(item.shiftEndTime) : '',
        checkInTime: item.checkIn ? moment(item.checkIn, "YYYY-MM-DD HH:mm").format("HH:mm") : '',
        checkOutTime: item.checkOut ? moment(item.checkOut, "YYYY-MM-DD HH:mm").format("HH:mm") : ''
      };
      return null;
    })
    return data;
  }

  const convertDateTimeForApproveList = data => {
    let checkIn = data.checkIn
      ? moment(data.checkIn.replace("T", " ")).format("DD MMM YYYY HH:mm")
      : "";
    let checkOut = data.checkOut
      ? moment(data.checkOut.replace("T", " ")).format("DD MMM YYYY HH:mm")
      : "";
    let shiftStartTime = moment(data.shiftStartTime.replace("T", " ")).format(
      "HH:mm"
    );
    let shiftEndTime = moment(data.shiftEndTime.replace("T", " ")).format(
      "HH:mm"
    );

    let oTminutes = data.oTminutes;
    var hours = oTminutes / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    let dhours = rhours > 0 ? `${rhours} Hour(s) ` : "";
    let dminutes = rminutes > 0 ? `${rminutes} Minute(s)` : "";
    let otDisplay = dhours + dminutes;

    return {
      ...data,
      checkIn,
      checkOut,
      shiftStartTime,
      otDisplay,
      shiftEndTime
    };
  };

  const getHistoryList = () => {
    return (
      <div>
        {employeelist
          .sort(function(a, b) {
            return moment(b.attendanceDate) - moment(a.attendanceDate);
          })
          .map((emp, index) => (
            <div
              className="historydetail"
              key={emp.attendanceUID}
              onClick={e => showDetail(emp)}
            >
              <div className="col">
                <div>
                  <span className="in">IN</span>{" "}
                  <span>
                    {emp.checkIn ? moment(emp.checkIn).format("HH:mm") : "--"}
                  </span>{" "}
                  <span className="float-right">
                    {formatDate(emp.attendanceDate)}
                  </span>
                </div>
                <div>
                  <span className="out">OUT</span>{" "}
                  <span>
                    {emp.checkOut
                      ? moment(emp.checkOut).format("HH:mm")
                      : "--"}
                  </span>{" "}
                  <span className="float-right"></span>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          ))}
      </div>
    );
  };

  const onRemoveItem = (index, type) => {
    if (type === "checkin") {
      const list = [...myState.checkinApprovalList];
      setMyState(st => ({
        ...st,
        checkinApprovalList: [...list.slice(0, index), ...list.slice(index + 1)]
      }));
    } else {
      const list = [...myState.checkoutApprovalList];
      let checkoutApprovalList = [
        ...list.slice(0, index),
        ...list.slice(index + 1)
      ];
      setMyState(st => ({
        ...st,
        checkoutApprovalList
      }));
    }
  };

  const getTabView = () => {
    switch (selectedTab) {
      case 0:
        return <div style={{ marginTop: 20 }}>{getHistoryList()}</div>;
      case 1:
        return (
          <AttendanceApprovalList
            key="checkin"
            list={myState.checkinApprovalList}
            type="checkin"
            onRemoveItem={onRemoveItem}
          />
        );
      case 2:
        return (
          <AttendanceApprovalList
            key="checkout"
            onRemoveItem={onRemoveItem}
            list={myState.checkoutApprovalList}
            type="checkout"
          />
        );// no default
    }
  };

  const AnyReactComponent = ({ text }) => <div><i className="fa fa-map-marker" aria-hidden="true" style={{ color: 'red', fontSize: 'x-large' }}></i>{text}</div>;

  useEffect(() => {
    function loadData() {
      Checkinout.CheckChekin(user, true) // from cache
        .then(result => {
          if (result) {
            setMyState(state => ({
              ...state,
              CheckInDetail: {
                checkIn: result.checkIn,
                attendanceDate: result.attendanceDate,
                attendanceUID: result.attendanceUID
              },
              ischeckin: true
            }))
          } else {
            setMyState(state => ({
              ...state,
              CheckInDetail: {
                checkIn: ''
              },
              ischeckin: false
            }))
          }
        })

      Checkinout.CheckOutDetail(user, user.empUID, true) // from cache
      .then(result => {
        if (result) {
          if (moment(result[0].attendanceDate).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD") || !result[0].checkOut) {
          setMyState(state => ({
            ...state,
            CheckOutDetail: {
              overTimeMin: result[0].overTimeMin,
              checkOut: result[0].checkOut,
              attendanceDate: result[0].attendanceDate,
              uid: result[0].uid
            },
            CheckInDetail: {
              checkIn: result[0].checkIn,
              attendanceDate: result[0].attendanceDate,
              uid: result[0].uid
            }
          }))
        } }
      })

      let promise = []

      promise.push(Checkinout.CheckChekin(user) // from network
        .then(result => {
          if (result.status !== "1") {
            setMyState(state => ({
              ...state,
              CheckInDetail: {
                checkIn: result.checkIn,
                attendanceDate: result.attendanceDate,
                attendanceUID: result.attendanceUID
              },
              ischeckin: true
            }))
          } else {
            setMyState(state => ({
              ...state,
              CheckInDetail: {
                checkIn: ''
              },
              ischeckin: false
            }))
          }
        }))

      promise.push(Checkinout.CheckOutDetail(user, user.empUID) // from network
      .then(result => {
        if (result.status !== "1") {
          if (moment(result[0].attendanceDate).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD") || !result[0].checkOut) {
            setMyState(state => ({
              ...state,
              CheckOutDetail: {
                overTimeMin: result[0].overTimeMin,
                checkOut: result[0].checkOut,
                attendanceDate: result[0].attendanceDate,
                uid: result[0].uid
              },
              CheckInDetail: {
                checkIn: result[0].checkIn,
                attendanceDate: result[0].attendanceDate,
                uid: result[0].uid
              }
            }))
          }          
        }
      }))
      let startDate = moment().subtract(30, 'day').format("YYYY-MM-DD");
      let endDate = moment().add(0, 'day').format("YYYY-MM-DD");

      Checkinout.AttendanceNoshift(user, startDate, endDate, true) // from cache
        .then(result => {
          if (result) {
            let requestList = getLocDateFromArray(result);
            setMyState(state => ({
              ...state,
              requestList,
              employeelist: result
            }))
            for (const item of result) {
              if (moment(item.checkOut).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD") && moment(item.checkIn).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD")) {
                setMyState(state => ({
                  ...state,
                  ischeckout: true
                }))
                break
              }
            }
          }
        })

      promise.push(Checkinout.AttendanceNoshift(user, startDate, endDate) // from network
        .then(result => {
          if (result.status !== '1') {
            let requestList = getLocDateFromArray(result);
            setMyState(state => ({
              ...state,
              requestList,
              employeelist: result
            }))
            for (const item of result) {
              if (moment(item.checkOut).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD") && moment(item.checkIn).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD")) {
                setMyState(state => ({
                  ...state,
                  ischeckout: true
                }))
                break
              }
            }
          }
        }))
      
        AttendenceAPI.ApprovalList("checkin", true) // from cache
        .then(result => {
          if (result) {
            let checkinApprovalList = result.map(convertDateTimeForApproveList);
            setMyState(state => ({
              ...state,
              checkinApprovalList
            }))
          }
        })

        AttendenceAPI.ApprovalList("checkout", true) // from cache
        .then(result => {
          if (result) {
            let checkoutApprovalList = result.map(convertDateTimeForApproveList);
            setMyState(state => ({
              ...state,
              checkoutApprovalList
            }))
          }
        })

      promise.push(AttendenceAPI.ApprovalList("checkin") // from network
        .then(result => {
          if (result.status !== '1') {
            let checkinApprovalList = result.map(convertDateTimeForApproveList);
            setMyState(state => ({
              ...state,
              checkinApprovalList
            }))
          }
        }))

      promise.push(AttendenceAPI.ApprovalList("checkout") // from network
        .then(result => {
          if (result.status !== '1') {
            let checkoutApprovalList = result.map(convertDateTimeForApproveList);
            setMyState(state => ({
              ...state,
              checkoutApprovalList
            }))
          }
        }))
      

      Promise.all(promise).then(() => {        
        console.log("finish from network")
        LoadingIndicator.instance.hideLoader()
      })
    }

    async function componentDidMount() {
      //refreshData();
      await LoadingIndicator.instance.showLoader();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(displayLocationInfo);
        
      } else {
        toast.error(t("validation.noGPS"));
      }
      loadData()
    }

    const startTimer =
      setInterval(() => {
        setNow(moment())
      }, 1000)

    componentDidMount()

    return function () {
      clearInterval(startTimer)
    }
  }, [user])

  let { CheckInDetail, CheckOutDetail, employeelist, open, open2, alertTitle, labelmesage, zoom, center, latitude, longitude } = myState;

  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <ArrowBackIcon style={{ color: '#fff', paddingRight: 15 }} onClick={() => props.history.push("/selfservice")}></ArrowBackIcon>
          <Typography variant="h6">{t("buttons.attendance")}</Typography>
          <RefreshIcon style={{ color: '#fff', position: 'absolute', right: 15 }} onClick={() => window.location.reload()}></RefreshIcon>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: 56 }}>
        {open &&
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" color="primary">
                {labelmesage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">{t("buttons.cancel")}</Button>
              <Button onClick={handleClose} color="primary" autoFocus>OK</Button>
            </DialogActions>
          </Dialog>
        }
        {open2 &&
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" color="primary">
                {labelmesage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">{t("buttons.cancel")}</Button>
              <Button onClick={loadImage2} color="primary" autoFocus>OK</Button>
            </DialogActions>
          </Dialog>
        }
        <Grid
          container
          spacing={3}
        >    
          <div style={{ height: '30vh', width: '100%'}}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyApMeAGsdAfi9Cf9-JGLZtMPKG0Po3vfUw' }}
              center={center}
              defaultZoom={zoom}
            >
            <AnyReactComponent
              lat={latitude}
              lng={longitude}
              text={''}
            />
            </GoogleMapReact>
          </div>
        {CheckInDetail !== null ?                
            <div className="checkin">
              <div className="text-center">
                {CheckInDetail.checkIn ?
                  (CheckOutDetail.checkOut ?
                    (<div className="checkinCol100">
                      <div style={{ borderBottom: '1px solid #ccc', paddingBottom: 5 }}><span className="text-large" style={{ color: '#0b998b' }}>CHECK IN</span><br /><span className="text-xlarge">{CheckInDetail.checkIn && moment(CheckInDetail.checkIn).format("ddd, DD MMM YYYY HH:mm")}</span></div>
                      <div style={{ paddingBottom: 5, paddingTop: 15 }}><span className="text-large" style={{ color: '#3f10a3' }}>CHECK OUT</span><br /><span className="text-xlarge">{CheckOutDetail.checkOut && moment(CheckOutDetail.checkOut).format("ddd, DD MMM YYYY HH:mm")}</span></div>
                    </div>) :
                    (<div className="checkinCol100">
                      <div style={{ borderBottom: '1px solid #ccc', paddingBottom: 5 }}><span className="text-large" style={{ color: '#0b998b' }}>CHECK IN</span><br /><span className="text-xlarge">{CheckInDetail.checkIn && moment(CheckInDetail.checkIn).format("ddd, DD MMM YYYY HH:mm")}</span></div>
                      <br />
                      <div className="text-large">{now.format("ddd, DD MMM YYYY")}</div>
                      <div className="text-xxlarge">{now.format("HH:mm:ss")}</div>
                    </div>)) :
                  <div className="checkinCol100">
                    <div className="text-large">{now.format("ddd, DD MMM YYYY")}</div>
                    <div className="text-xxlarge">{now.format("HH:mm:ss")}</div>
                  </div>}                  
                {CheckInDetail.checkIn ?
                  (CheckOutDetail.checkOut ?
                    (<div className="text-center" style={{ left: 0, marginTop: '-80px' }}>
                      <Link className="checkoutBtnBigDisabled">CHECK IN</Link>
                    </div>) :
                    (<div className="text-center" style={{ left: 0, marginTop: '-80px' }}>
                      <input name='profilePhoto2' type="file" accept="image/*" capture="camera" id='profilePhoto2' onChange={checkoutNoshift} style={{ display: 'none' }} />
                      <Link className="checkoutBtnBig" onClick={submitCheckout}>CHECK OUT</Link>
                    </div>)) :
                  (<div className="text-center" style={{ left: 0, marginTop: '-80px' }}>
                    <input name='profilePhoto' type="file" accept="image/*" capture="camera" id='profilePhoto' onChange={checkinNoshift} style={{ display: 'none' }} />
                    <Link className="checkinBtnBig" onClick={submitCheckin}>CHECK IN</Link>
                  </div>)
                }
                <div className="clearfix"></div>
              </div>
            </div> : "" }
            
            {isServiceManager ? (
            <Grid item xs>
              <Tabs
                value={selectedTab}
                onChange={onChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="leave tabs"
              >
                <Tab label={t("buttons.myHistory")} />
                <Tab label={t("text.approvalCheckin")} />
                <Tab label={t("text.approvalCheckout")} />
              </Tabs>
              {getTabView()}
            </Grid>
          ) : (
            <div className="history">
              <p className="text-large text-bold" style={{ paddingLeft: 15 }}>
                {t("buttons.myHistory")}
              </p>
              {getHistoryList()}
            </div>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default Attendance

/*const CurrentTime = () => {
  const getFDT = () => {
    //let d = moment();
    let d = now;
    return {
      date: d.format("ddd, DD MMM YYYY"),
      time: d.format("HH:mm:ss")
    };
  };

  useEffect(() => {
    const startTimer = setInterval(() => {
      setNow(getFDT);
    }, 1000);

    return () => {
      clearInterval(startTimer);
    };
  });

  const [now, setNow] = useState(getFDT);

  return (
    <>
      <div className="text-large">{now.date}</div>
      <div className="text-xxlarge">{now.time}</div>
    </>
  );
};*/
class AttendanceApprovalList extends React.PureComponent {
  state = {
    isModalOpen: false
  };
  reasonInput = "";
  selectedIndex = -1;
  selectedEmp = {};

  onChangeText = e => {
    this.reasonInput = e.target.value;
  };

  handleDialog = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  };

  onClickApprove = async () => {
    await LoadingIndicator.instance.showLoader();
    let response;
    let item = this.selectedEmp;
    let employees = JSON.stringify([
      {
        uid: item.uid,
        employeeuid: item.employeeUID,
        overtimemins: this.reasonInput
      }
    ]);
    let { type } = this.props;
    if (type === "checkin") {
      response = await AttendenceAPI.ApproveCheckin(employees);
    } else {
      response = await AttendenceAPI.ApproveCheckout(employees);
    }
    if (response) {
      toast.success(t("apiMessages.saved"));
      // this.setState({
      //   isModalOpen: false
      // });
      this.props.onRemoveItem(this.selectedIndex, type);
    }
    LoadingIndicator.instance.hideLoader();
  };

  onClickMenuItem = index => () => {
    let emp = this.props.list[index];
    this.selectedEmp = emp;
    this.selectedIndex = index;
    // if (emp.overTime === 1) {
    //   this.reasonInput = emp.oTminutes;
    //   this.setState({
    //     isModalOpen: true
    //   });
    // } else
    this.onClickApprove();
  };

  getApprovalList = () => {
    let { list, type } = this.props;
    return list.map((item, index) => (
      <Grid
        container
        style={{
          paddingTop: 10,
          paddingLeft: 15,
          paddingRight: 15
        }}
        key={item.uid}
      >
        <Grid item container xs={12}>
          <Grid item xs>
            <Typography variant="body1">{item.employeeName}</Typography>
          </Grid>
        </Grid>
        <Grid xs={12} alignItems="center" item container>
          <Grid item xs>
            <Typography variant="body2">
              {t("text.shiftTime")} {item.shiftStartTime} - {item.shiftEndTime}
            </Typography>
            <Typography variant="body2">
              {item.checkIn}
              {type === "checkout" && ` - ${item.checkOut}`}
            </Typography>

            {item.overTime === 1 && (
              <Typography variant="body2">
                {t("text.overTime")} {item.otDisplay}
              </Typography>
            )}

            <Typography variant="body2">{item.shiftName}</Typography>
          </Grid>
          <Grid item>
            <ApprovalOptionMenu
              onClickMenu={this.onClickMenuItem(index)}
              menuList={[t("buttons.approve")]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    ));
  };

  render() {
    let { selectedEmp, props } = this;
    let { type } = props;
    return (
      <>
        {type === "checkout" && (
          <Dialog
            disableEnforceFocus
            open={this.state.isModalOpen}
            onClose={this.handleDialog}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {t("text.overTime")}
            </DialogTitle>

            <ValidatorForm onSubmit={this.onClickApprove}>
              <DialogContent>
                <Typography variant="body1">
                  {selectedEmp.employeeName}
                </Typography>

                <Typography variant="body2">
                  {t("text.shiftTime")} {selectedEmp.shiftStartTime} {" - "}
                  {selectedEmp.shiftEndTime}
                </Typography>
                <Typography variant="body2">
                  {selectedEmp.checkIn} - {selectedEmp.checkOut}
                </Typography>

                <Typography variant="body2">
                  {t("text.overTime")} {selectedEmp.otDisplay}
                </Typography>

                <TextValidator
                  autoFocus
                  name="overTime"
                  value={this.reasonInput}
                  type="number"
                  onChange={this.onChangeText}
                  label={t("input.overTime")}
                  margin="normal"
                  variant="outlined"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="text"
                  onClick={this.handleDialog}
                  color="inherit"
                >
                  {t("buttons.close")}
                </Button>
                <Fab type="submit" variant="extended">
                  {t("buttons.approve")}
                </Fab>
              </DialogActions>
            </ValidatorForm>
          </Dialog>
        )}
        {this.getApprovalList()}
      </>
    );
  }
}