export const IS_DEVEOPMENT = false;

const DevelopmentURL = "https://sarvamprodapi.azurewebsites.net/";
const ProductionURL = "https://sarvamprodapi.azurewebsites.net/";

export const BASE_URL = IS_DEVEOPMENT ? DevelopmentURL : ProductionURL;

export const USER_TYPE = "user";

export const NAV_STRINGS = {
  signup: "signup",
  dataEntry: "deverify",
  login: "login",
  changePassword: "CHANGE_PASSWORD",
  externalAccess: "EXTERNAL_ACCESS",
  reports: "REPORTS",
  users: "USERS",
  dashboard: "DASHBOARD"
};

export const USER_ROLE = {
  admin: /Administrator/i,
  manager: /manager/i,
  dataEntry: /dataentry/i,
  user: /user/i
};

const VALIDATION_STRING = "validation.";
const API_STRING = "apiMessages.";
export const TRANS_STRING = {
  COMMON_STRING: "commonText.",
  API_STRING,
  VALIDATION_STRING,
  SAVED_STRING: API_STRING + "saved",
  INPUT_REQ: VALIDATION_STRING + "required",
  INPUT_INVALID: VALIDATION_STRING + "invalid"
};

export const MASTER_NAMES =
  "ProgramFee,UniversityProgram,UniversityProgramType," +
  "Province,Country,Accreditation,ProgramType,MediaType,City";

export const MEDIA_TYPE_IMAGE = /image/i;

export const Colors = {
  primary: "#5E35B1",
  secondary: "#F68E58",
  grey: "#707070",
  dark: "#4D4F5C",
  tableHeadColor: "#F5F6FA",
  pendingStatus: "#ff9d00",
  buttonPrimary: "#abede7"
  // backgroundColor: "#E9E9F0"
};

export const ImageTypes = {
  portrait: "portrait",
  landscape: "landscape"
};

const STORE_MAIN_KEY = "@sarvam:";
export const STORAGE_NAMES = {
  leaveApprovePopup: `${STORE_MAIN_KEY}leaveApprovePopup`,
  attendanceThisMonth: `${STORE_MAIN_KEY}attendanceThisMonth`
};
