import { formDataConsole, DEV_URL, checkAPIError } from "../_helper/apiMethods";
import { devLog } from "../_helper/commonMethods";
import store from '../_helper/helper.store';
import { localCache } from "@prestasi/cache";

export const List = (user, OrgUnitUID, type, isactive) => {
    if (OrgUnitUID === undefined) {
        OrgUnitUID = "";
    }
    if (type === undefined) {
        type = "";
    }
    if (isactive === undefined) {
        isactive = "";
    }
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('ListName', 'emp');
    formData.append('OrgUnitUID', OrgUnitUID);
    formData.append('Type', type);
    formData.append('IsActive', isactive);
    formDataConsole(formData);
    let url = '/api/hrms/employee/list'
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// employee list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Detail = (user, employee, detailtype, datauid, fromCache = false) => {
    var formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('DetailType', 'basic');
    formData.append('DataUID', user.empUID);
    //console.log(detailtype)
    /*if (detailtype) {
        formData.append('DetailType', detailtype);
        formData.append('DataUID', datauid);
    } else {
        formData.append('DetailType', "");
        formData.append('DataUID', employee ? employee.empUID : user.empUID);
    }*/
    let url = '/api/hrms/employee/detail';
    formDataConsole(formData)
    if (fromCache) {
        console.log("Employee detail fetch from cache")
        return Promise.resolve(JSON.parse(localCache.getItem("/api/hrms/employee/detail")))
    }

    console.log("Employee detail fetch from network")
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// employee detail response", res)
            localCache.setItem("/api/hrms/employee/detail", JSON.stringify(res))
            return res;
        })
        .catch(checkAPIError);
}

export const DetailFamily = (user, fromCache = false) => {
    var formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('DetailType', 'familylist');
    formData.append('DataUID', user.empUID);
    let url = '/api/hrms/employee/detail';
    formDataConsole(formData)
    if (fromCache) {
        console.log("Employee detail family fetch from cache")
        return Promise.resolve(JSON.parse(localCache.getItem("/api/hrms/employee/detail/family")))
    }

    console.log("Employee detail family fetch from network")
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// employee detail family response", res)
            localCache.setItem("/api/hrms/employee/detail/family", JSON.stringify(res))
            return res;
        })
        .catch(checkAPIError);
}

export const DetailAddress = (user, fromCache = false) => {
    var formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('DetailType', 'addresslist');
    formData.append('DataUID', user.empUID);
    let url = '/api/hrms/employee/detail';
    formDataConsole(formData)
    if (fromCache) {
        console.log("Employee detail address fetch from cache")
        return Promise.resolve(JSON.parse(localCache.getItem("/api/hrms/employee/detail/address")))
    }

    console.log("Employee detail address fetch from network")
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// employee detail address response", res)
            localCache.setItem("/api/hrms/employee/detail/address", JSON.stringify(res))
            return res;
        })
        .catch(checkAPIError);
}

export const Add = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formDataConsole(formData);
    let url = '/api/hrms/employee/add';
    //let directory = '../../../';
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// employee add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (state, formData, user, directory) => {
    let { language: { ipaddress } } = store.getState();
    formData.append("EmpUID", state.empUID);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formDataConsole(formData)
    let url = '/api/hrms/employee/update';
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// employee update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Deactivate = (user) => {
    var formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.userUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmpUID', user.empUID);
    formDataConsole(formData);
    let url = '/api/hrms/employee/deactivate';
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// employee deactivate response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const UploadProfilePicture = (state, user, formData, directory) => {
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmpUID', state.empUID);
    let url = '/api/hrms/employee/uploadprofilepicture';
    formDataConsole(formData);
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// employee upload picture response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const ResetPassword = (formData, user, directory, EmpUID) => {
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append("EmpUID", EmpUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formDataConsole(formData);
    let url = '/api/login/forget';
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// rest password response", res)
            return res;
        })
        .catch((err) => {
            devLog('error', err)
        })
        .catch(checkAPIError);
}

export const RoleAddUpdate = (formData, user, EmpUID) => {
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append("EmployeeUID", EmpUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formDataConsole(formData);
    let url = '/api/hrms/employee/role/addupdate';
    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// role addupdate response", res)
            return res;
        })
        .catch(checkAPIError);
}
export const Checkin = (formData, user, attendanceUID) => {
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmployeeUID', user.empUID);
    formData.append('UID', attendanceUID);
    formData.append('ClientType', 'Web');
    formData.append('SSID', null);
    formDataConsole(formData);
    let url = '/api/hrms/attendance/2/checkin';
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// checkin response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const CheckInDetail = (user, EmployeeUID, lat, lang) => {
    let { language: { ipaddress } } = store.getState();
    let formData = new FormData();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmployeeUID', EmployeeUID);
    formData.append('Latitude', '');
    formData.append('Longitude', '');
    let url = '/api/hrms/attendance/checkin/detail';
    formDataConsole(formData);
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// checkin detail response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const CheckOutDetail = (user, EmployeeUID, lat, lang) => {
    let { language: { ipaddress } } = store.getState();
    let formData = new FormData();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmployeeUID', EmployeeUID);
    formData.append('Longitude', '');
    formData.append('Latitude', '');
    let url = '/api/hrms/attendance/checkout/detail';
    formDataConsole(formData);
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// checkin detail response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Checkout = (formData, user, attendanceUID) => {
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmployeeUID', user.empUID);
    formData.append('UID', attendanceUID);
    formData.append('ClientType', 'Web');
    formData.append('SSID', null);
    formDataConsole(formData);
    let url = '/api/hrms/attendance/2/checkout';
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// checkin response", res)
            return res;
        })
        .catch(checkAPIError);
}