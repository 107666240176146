import React from "react";
import Modal from "react-responsive-modal";
import { ClipLoader } from "react-spinners";
import { t } from "../_language";
import { TRANS_STRING } from "../Const";
import { Typography, Portal } from "@material-ui/core";
export const LoaderTextType = {
  saving: 1,
  custom: 2
};
let { API_STRING } = TRANS_STRING;
class LoadingIndicator extends React.PureComponent {
  TEXT_STRINGS = {
    SAVING_STRING: API_STRING + "saving",
    LOADING_STRING: API_STRING + "loading"
  };
  getLoadingText = (type, text) => {
    switch (type) {
      case LoaderTextType.saving:
        return t(this.TEXT_STRINGS.SAVING_STRING);
      case LoaderTextType.custom:
        return t(text);
      default:
        return t(this.TEXT_STRINGS.LOADING_STRING);
    }
  };

  state = {
    text: "",
    modalVisible: false
  };

  static instance = null;

  getModalState = () => {
    return this.state.modalVisible;
  };

  showLoader = (type, text) => {
    return new Promise(resolve => {
      this.setState(
        {
          //text: this.getLoadingText(type, text),
          modalVisible: true
        },
        resolve
      );
    });
  };

  hideLoader = () => {
    this.setState({
      modalVisible: false
    });
  };

  render() {
    let { modalVisible, text } = this.state;
    return (
      <Portal>
        <Modal
          open={modalVisible}
          center
          styles={{
            modal: {
              boxShadow: "none",
              borderRadius: 5,
              backgroundColor: "transparent"
            },
            overlay: {
              //backgroundColor: "rgba(0, 0, 0, 0)",
              backgroundColor: "#5E35B1",
              padding: 0,
              width: 56,
              height: 56
            }
          }}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          onClose={this.hideLoader}
          showCloseIcon={false}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: 50
            }}
          >
            <div
              style={{
                position: "relative",
                height: 30
              }}
            >
              <ClipLoader
                //color={Colors.primary}
                color={"#fff"}
                size={30}
                loading={modalVisible}
              />
            </div>

            <Typography tabIndex="1" variant="subtitle2">
              {text}
            </Typography>
          </div>
        </Modal>
      </Portal>
    );
  }
}
export default LoadingIndicator;
