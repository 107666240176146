import React, { useEffect, useState } from "react";
import { Footer, Header } from "../component";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Employee, CoreMaster, Checkinout } from "../_api";
import moment from "moment";
import { LoadingIndicator2 } from "../component";
//import { toast } from "react-toastify";
import { useSelector, useDispatch } from 'react-redux'
import { t } from "../_language";
import { localCache } from "@prestasi/cache";
import { MASTER_DATA } from "../_reducers/reducer.user";
import { Leave } from "../_api";

function HomePage(props) {
  //this.props.addTranslationForLanguage(id, "id");
  //this.props.addTranslationForLanguage(en, "en"); 
  const [myState, setMyState] = useState({
    employeeCode: '',
    positionName: '',
    photoUrl: '',
    appliedList: [],
    CheckInDetail: {},
    CheckOutDetail: {},
    ischeckout: false,
    imgSelfUrl: '',
    imgRecruitmentUrl: '',
    imgPerformanceUrl: '',
    imgOkrUrl: '',
    imgClockUrl: '',
    imgUserUrl: ''
  })
  const [now, setNow] = useState(moment())
  //get state from store
  const user = useSelector(state => state.user).user
  const dispatch = useDispatch()

  const toDataURL = (e) => {
    let img = e.target
    var canvas = document.createElement('canvas');
    canvas.width = img.naturalWidth; // or 'width' if you want a special/scaled size
    canvas.height = img.naturalHeight; // or 'height' if you want a special/scaled size

    canvas.getContext('2d').drawImage(img, 0, 0);

    // ... or get as Data URI
    let base64Img = canvas.toDataURL('image/png')
    localCache.setItem(img.name, base64Img)
  }

  useEffect(() => {
    function preFetchData() { //prefetch data for profile, attendance, leave and applyleave page
      let startDate = moment().subtract(1000, 'day').format("YYYY-MM-DD");
      let endDate = moment().add(1000, 'day').format("YYYY-MM-DD");
      let employeeuid = user.empUID;
      if (!localCache.getItem("/api/hrms/leave/request/list"))
        Leave.RequestList(user, employeeuid, startDate, endDate);
      if (!localCache.getItem("/api/hrms/leave/master/list"))
        Leave.MasterList(user, employeeuid);
      if (!localCache.getItem("/api/hrms/employee/detail"))
        Employee.Detail(user)
    }
    function componentDidMount() {
      LoadingIndicator2.instance.showLoader();
      /*let url = "../";

      Employee.Detail(user, url)
        .then((empres) => {
          if (empres.status !== '1') {
            setMyState(state => ({
              ...state,
              employeeCode: empres.employeeCode,
              positionName: empres.positionName
            }))
            if (empres.photoUrl) {
              getBlobFromURL(empres.photoUrl);
            }
          } else {
            toast.error(empres.message);
          }
          LoadingIndicator.instance.hideLoader()
        })

       Candidates.AppliedList(user, "")
        .then(res => {
          let applied = res.res
          if (applied.status !== "1") {
            if (applied.photoUrl) {
              getBlobFromURL(applied.photoUrl);
            }
            setMyState(state => ({ ...state, appliedList: applied }))
          } else {
            toast.error(applied.message);
          }
          LoadingIndicator.instance.hideLoader()
        }) */
      setMyState(state => ({
        ...state,
        imgSelfUrl: localCache.getItem("imgself"),
        imgRecruitmentUrl: localCache.getItem("imgrecruitment"),
        imgPerformanceUrl: localCache.getItem("imgperformance"),
        imgOkrUrl: localCache.getItem("imgokr"),
        imgClockUrl: localCache.getItem("imgclock"),
        imgUserUrl: localCache.getItem("defaultimage")
      }))

      Checkinout.CheckChekin(user, true) // from cache
        .then(result => {
          if (result) {
            setMyState(state => ({
              ...state,
              CheckInDetail: result
            }))
          }

        })
        .then(LoadingIndicator2.instance.hideLoader())

      Checkinout.CheckChekin(user) // from network
        .then(result => {
          if (result.status !== "1") {
            setMyState(state => ({
              ...state,
              CheckInDetail: result
            }))
          }

        })
        .then(LoadingIndicator2.instance.hideLoader())
      //)

      

      Checkinout.AttendanceNoshift(user, moment().subtract(30, 'day').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"), true) // from cache
        .then(result => {
          if (result) {
            for (const item of result) {
              if (moment(item.checkOut).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD") && moment(item.checkIn).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD")) {
                setMyState(state => ({
                  ...state,
                  ischeckout: true
                }))
                break //quit
              }
            }
          }
          LoadingIndicator2.instance.hideLoader()
        })

      Checkinout.AttendanceNoshift(user, moment().subtract(30, 'day').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")) // from network
        .then(rescheckout => {
          if (rescheckout.status !== "1") {
            for (const item of rescheckout) {
              if (moment(item.checkOut).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD") && moment(item.checkIn).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD")) {
                setMyState(state => ({
                  ...state,
                  ischeckout: true
                }))
                break //quit
              }
            }
          }
          LoadingIndicator2.instance.hideLoader()
        })

      const getBlobFromURL = async (blob) => {
        let url = '../';
        let res = await CoreMaster.AccessBlob(user, blob, url);
        if (res.status !== '1') {
          setMyState(state => ({
            ...state,
            photoUrl: res.message
          }))
        }
      }

      if (user.photoURL) {
        getBlobFromURL(user.photoURL);
      }

      CoreMaster.List(null, null, '', true) // from cache return null if not exists
        .then(function (masterlist) {
          dispatch({ type: MASTER_DATA, master: masterlist })
        })

      CoreMaster.List(null, null, '')
        .then(function (masterlist) {
          if (masterlist.status !== '1') {
            dispatch({ type: MASTER_DATA, master: masterlist })
          }
        })
    }

    const startTimer =
      setInterval(() => {
        setNow(moment())
      }, 1000)
    componentDidMount();
    preFetchData()
    return function () {
      clearInterval(startTimer)
    }
  }, [user, dispatch])

  let { photoUrl, CheckInDetail, ischeckout, imgSelfUrl, imgRecruitmentUrl, imgPerformanceUrl, imgOkrUrl, imgClockUrl, imgUserUrl } = myState;
  let defaultimage = imgUserUrl || "/assets/images/user.png";
  return (
    <React.Fragment>
      <Header />
      <div>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 70 }}
        >
          <Grid item md={12} style={{ textAlign: 'center', width: '100%', paddingTop: 15, paddingBottom: 100, zIndex: 99, background: 'linear-gradient(180deg, rgba(98,43,217,1) 50%, rgba(132,81,229,1) 100%)' }}>
            <Link to="/profile">
              <img
                alt='user'
                style={{ borderRadius: '50%', width: 80, height: 80, display: 'inline-block', border: '5px solid #fff', zIndex: 999 }}
                src={photoUrl || defaultimage}
                name="photourl" />
            </Link>
            <Typography variant="h6" className="text-white">{user.empName}</Typography>
            <Typography variant="body2" className="text-white">{user.positionName}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ paddingBottom: 20 }}>
          <div className="topMenu">
            <div className="topMenuItem"><Link to={{ pathname: "/selfservice" }}><div className="boxImg"><img src={imgSelfUrl || "/assets/images/self.png"} alt="Self Service" style={{ height: 30 }} name="imgself" onLoad={toDataURL} /></div>Self Service</Link></div>
            <div className="topMenuItem"><Link to="#"><div className="boxImg"><img src={imgRecruitmentUrl || "/assets/images/recruitment.png"} alt="Recruitment" style={{ height: 30 }} name="imgrecruitment" onLoad={toDataURL} /></div>Recruitment</Link></div>
            <div className="topMenuItem"><Link to="#"><div className="boxImg"><img src={imgPerformanceUrl || "/assets/images/performance.png"} alt="Performance" style={{ height: 30 }} name="imgperformance" onLoad={toDataURL} /></div>{t("buttons.performance")}</Link></div>
            <div className="topMenuItem"><Link to="#"><div className="boxImg"><img src={imgOkrUrl || "/assets/images/okr.png"} alt="OKR" style={{ height: 30 }} name="imgokr" onLoad={toDataURL} /></div>OKR</Link></div>
          </div>
        </Grid>
        <Grid
          container
          spacing={3}
        >
          <div className="checkin">
            <div className="checkinCol">
              <div className="text-large">{now.format("ddd, DD MMM YYYY")}</div>
              <div className="text-xxlarge">{now.format("HH:mm:ss")}</div>
            </div>
            <div className="checkinCol text-center" style={{ marginTop: 15 }}>
              {(CheckInDetail !== null && ischeckout === false) ? <Link className={CheckInDetail.checkIn ? "checkoutBtn" : "checkinBtn"} to={{ pathname: "/attendance" }}><img src={imgClockUrl || "/assets/images/clock.png"} alt="Attendance" style={{ height: 15 }} name="imgclock" onLoad={toDataURL} />&nbsp;&nbsp;{CheckInDetail.checkIn ? <span>CHECK OUT</span> : <span>CHECK IN</span>}</Link> : <span className="checkinBtnDisabled"><img src={imgClockUrl || "/assets/images/clock.png"} alt="Attendance" style={{ height: 15 }} name="imgclock" onLoad={toDataURL} />&nbsp;&nbsp;CHECK IN</span>}
            </div>
          </div>
        </Grid>
        <div className="clearfix"></div>
        {/* 
        <Grid
          container
          spacing={3}
        >
          <div style={{ flex: 1, marginTop: 0, width: '100%' }}>
            <p style={{ marginBottom: 0, fontSize: 20, marginLeft: '5%' }}>{t("buttons.newCandidate")}</p>
            <div className="newsList">
              {appliedList.map((item, key) => (
                <div key={key} className="newsListItem">
                  <div>
                    <span className="text-center"><img src={item.photoURL || defaultimage} className="imgNews" alt="" /></span>
                    <p style={{ fontSize: 20, color: '#000000', padding: 10, margin: 0 }}>{item.firstname + " " + item.lastname}</p>
                    <p className="newsdesc2" style={{ margin: 0 }}>
                      {item.jobTitle}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Grid>*/}
      </div>
      <Footer active="home" />
    </React.Fragment>
  );
}

export default HomePage;