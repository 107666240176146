export const UPDATE = 'update'
export const UPDATEIP = 'updateIP'
const initialState = {
   activelanguagepage:"en",
   ipaddress:''
}

export default (state = initialState, action) => {
     switch (action.type) {
        case UPDATE:
        return {
            ...state,
            activelanguagepage:action.activelanguagepage
        }
        case UPDATEIP:
        return{
            ...state,
            ipaddress:action.ipaddress,
            activelanguagepage:initialState.activelanguagepage
        }
        default:
            return state
    }
}

export const setLanguagePage = (res) => {
    return dispatch => {
        dispatch({
            type:UPDATE,
            activelanguagepage: res
        })
    }
}


export const setIP = (res) => {
     return dispatch => {
        dispatch({
            type:UPDATEIP,
            ipaddress: res
        })
    }
}

