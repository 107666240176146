import React, { useState, useEffect } from "react";
import { Grid, Typography, AppBar, Toolbar, Button, Tab, Tabs, FormControlLabel, Checkbox, DialogActions, DialogContent, Dialog, DialogTitle, Fab, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from "moment";
import { Leave } from "../_api";
import { toast } from "react-toastify";
import { t } from "../_language";
import { LoadingIndicator, ApprovalOptionMenu, TabBadge } from "../component";
import { formatDate } from '../_helper/apiMethods';
import { useSelector } from 'react-redux';
import Carousel from 'react-bootstrap/Carousel';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { STORAGE_NAMES, Colors } from "../Const";

function Leaves(props) {
  const [myState, setMyState] = useState(
    {
      dateselected: moment(),
      requestList: [],
      employeelist: [],
      leaveList: [],
      approvalList: [],
      selectedTab: 0,
      selectedMenuIndex: -1,
      selectedLeave: null,
      isServiceManager: props.location.state || {}, 
      isModalOpen: false,
      reasonInput: ""
    }
  )
  let statusRejectReg = /rejected/i;

  let dontShowPopupCheckbox = false;
  // get value from store
  const user = useSelector(state => state.user).user

  const convertDateTimeForApproveList = (data) => {
    return {
      ...data,
      startDate: moment(data.startDate).format("DD MMM YYYY"),
      endDate: moment(data.endDate).format("DD MMM YYYY")
    };
  };

  const getLocDateFromArray = (res) => {
    let data = [...res];
    data.sort(function (a, b) { return moment(a.requestAt) - moment(b.requestAt) }).map((item, i) => {
      data[i] = {
        ...item,
        startDate: item.startDate ? formatDate(item.startDate) : '',
        endDate: item.endDate ? formatDate(item.endDate) : '',
        requestAt: item.requestAt ? formatDate(item.requestAt) : '',
        cancelAt: item.cancelAt ? formatDate(item.cancelAt) : ''
      };
      return null;
    })
    return data;
  }

  const onChangeTab = (e, value) => {
    setMyState(state => ({
      ...state,
      selectedTab: value
    }))
  };

  const onClickMenuItem = leave => index => {
    setMyState(state => ({
      ...state,
      selectedLeave: leave
    }))
    let shouldHidePopup = localStorage.getItem(STORAGE_NAMES.leaveApprovePopup);

    if (index === 1 || shouldHidePopup === "false")
    setMyState(state => ({
      ...state,
      isModalOpen: true,
      selectedMenuIndex: index
    }))
    else onPressApproveOrReject();
  };

  const onChangeText = e => {
    setMyState(state => ({
      ...state,
      reasonInput: e.target.value
    }))
  };

  const onChangeCheckBox = ev => {
    dontShowPopupCheckbox = ev.target.checked;
  };

  const onPressApproveOrReject = async () => {
    await LoadingIndicator.instance.showLoader();
    let { uid, employeeUID } = myState.selectedLeave;
    let response = null;
    if (myState.selectedMenuIndex === 0)
      response = await Leave.Approve(employeeUID, uid, myState.reasonInput);
    else
      response = await Leave.Reject(employeeUID, uid, myState.reasonInput);
    if (response) {
      setMyState(state => ({
        ...state,
        isModalOpen: false,
        approvalList: myState.approvalList.filter(
          item => item.uid !== myState.selectedLeave.uid
        )
      }))
      localStorage.setItem(
        STORAGE_NAMES.leaveApprovePopup,
        dontShowPopupCheckbox
      );
      toast.success(t("apiMessages.saved"));
    }
    LoadingIndicator.instance.hideLoader();
  };

  const handleDialog = () => {
    setMyState(state => ({
      ...state,
      isModalOpen: !myState.isModalOpen
    }))
  };

  const getApprovalList = () => {
    return myState.approvalList.map(item => (
      <Grid
        container
        style={{
          paddingTop: 10,
          paddingLeft: 15,
          paddingRight: 15
        }}
        key={item.uid}
      >
        <Grid item container xs={12}>
          <Grid item xs>
            <Typography variant="body1">{item.employeeName}</Typography>
          </Grid>

          <Grid item>
            <Typography
              variant="overline"
              style={{ color: Colors.pendingStatus }}
            >
              {t("statusText.pending")}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} alignItems="center" item container>
          <Grid item xs>
            <Typography variant="body2">
              {item.startDate} - {item.endDate}
            </Typography>
            <Typography variant="body2">{item.leaveName}</Typography>
          </Grid>
          <Grid item>
            <ApprovalOptionMenu
              menuList={[t("buttons.approve"), t("buttons.reject")]}
              onClickMenu={onClickMenuItem(item)}
            />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
      </Grid>
    ));
  };

  const getLeaveRequestList = () => {
    return (
      <div>
        {myState.requestList
          .sort(function(a, b) {
            return moment(a.requestAt) - moment(b.requestAt);
          })
          .map((emp, index) => (
            <div className="historydetail" key={emp.uid}>
              <div className="col">
                <div>
                  <span className="in">START</span> <span>{emp.startDate}</span>{" "}
                  {emp.leaveStatus === "New" ||
                  emp.leaveStatus === "Approved" ? (
                    <span className="float-right text-uppercase text-green">
                      {emp.leaveStatus}
                    </span>
                  ) : (
                    <span className="float-right text-uppercase text-red">
                      {emp.leaveStatus}
                    </span>
                  )}
                </div>
                <div>
                  <span className="out">END</span> <span>{emp.endDate}</span>{" "}
                  <span className="float-right"></span>
                </div>
                <div>{emp.leaveName}</div>
                {statusRejectReg.test(emp.leaveStatus) && (
                  <Typography variant="caption" component="div">
                    {t("text.rejectedReason")} : {emp.remarks}
                  </Typography>
                )}
              </div>
              <div className="clearfix"></div>
            </div>
          ))}
      </div>
    );
  }

  useEffect(() => {
    async function componentDidMount() {
      await LoadingIndicator.instance.showLoader();
      let startDate = moment().subtract(1000, 'day').format("YYYY-MM-DD");
      let endDate = moment().add(1000, 'day').format("YYYY-MM-DD");
      let employeeuid = user.empUID;

      let startTimer = new Date()
      Leave.RequestList(user, employeeuid, startDate, endDate, true) // from cache
        .then(result => {
          if (result) {
            let requestList = getLocDateFromArray(result);
            setMyState(state => ({
              ...state,
              requestList
            }))
          }
        })

      Leave.MasterList(user, employeeuid, true) // from cache
        .then(result => {
          if (result) {
            setMyState(state => ({
              ...state,
              leaveList: result
            }))
          }
        })

      let reqlist = Leave.RequestList(user, employeeuid, startDate, endDate); // from network
      let res = Leave.MasterList(user, employeeuid); // from network
      let pList = [reqlist, res];
      //let { isServiceManager } = myState;
      if (myState.isServiceManager) pList = [...pList, Leave.ApprovalList()];

      Promise.all(pList).then((result) => {
        let requestList = [];
        let leaveList = [];
        let approvalList = [];
        if (result[0].status !== "1") {
          requestList = getLocDateFromArray(result[0]);
          setMyState(state => ({
            ...state,
            requestList
          }))
        }
        if (result[1].status !== "1") {
          leaveList = result[1];
          setMyState(state => ({
            ...state,
            leaveList
          }))
        }
        if (myState.isServiceManager && result[2]) {
          approvalList = result[2].map(convertDateTimeForApproveList);
          setMyState(state => ({
            ...state,
            approvalList
          }))          
        }

        console.log("Loading data in ", new Date() - startTimer, "ms")
        LoadingIndicator.instance.hideLoader()
      })

    }

    componentDidMount()

  }, [user, myState.isServiceManager])

  let { isServiceManager, reasonInput, selectedTab, leaveList, approvalList, isModalOpen, selectedMenuIndex } = myState;
  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <ArrowBackIcon style={{ color: '#fff', paddingRight: 15 }} onClick={() => props.history.push("/selfservice")}></ArrowBackIcon>
          <Typography variant="h6">{t("buttons.leaves")}</Typography>
          <RefreshIcon style={{ color: '#fff', position: 'absolute', right: 15 }} onClick={() => window.location.reload()}></RefreshIcon>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: 56 }}>
        <Grid
          container
          spacing={3}
        >
          <div className="checkin">
            <div className="text-center">
              <div className="checkinCol100">
                <div className="text-large text-bold" style={{ color: '#5E35B1', textTransform: 'uppercase', paddingBottom: 15 }}>{t("buttons.leaveBalance")}</div>
                <Carousel>
                  {leaveList.map((item, index) => (
                    <Carousel.Item key={index}>
                      <div className="text-xxlarge text-center">{item.balance}</div>
                      <div className="text-medium text-center">{item.leaveName}</div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
              <div className="text-center" style={{ left: 0, marginTop: '-80px' }}>
                <Link className="checkinBtnBig" to={{ pathname: "/applyleave" }}>{t("buttons.applyLeave")}</Link>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
          {isServiceManager && user.numOfStaff > 0 ? (
              <Grid item xs>
                <Tabs
                  value={selectedTab}
                  onChange={onChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="leave tabs"
                >
                  <Tab label={t("buttons.myHistory")} />
                  <Tab
                    label={
                      <TabBadge
                        color="primary"
                        badgeContent={approvalList.length}
                      >
                        {t("buttons.approval")}
                      </TabBadge>
                    }
                  />
                </Tabs>
                {selectedTab === 0 ? (
                  <div style={{ marginTop: 20 }}>
                    {getLeaveRequestList()}
                  </div>
                ) : (
                  getApprovalList()
                )}
              </Grid>
            ) : (
              <div className="history">
                <p className="text-large text-bold" style={{ paddingLeft: 15 }}>
                  {t("buttons.myHistory")}
                </p>
                {getLeaveRequestList()}
              </div>
            )}
        </Grid>
      </div>
      <Dialog
          open={isModalOpen}
          onClose={handleDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {selectedMenuIndex === 1
              ? t("text.rejectTitle")
              : t("text.approveTitle")}
          </DialogTitle>

          <ValidatorForm onSubmit={onPressApproveOrReject}>
            <DialogContent>
              <TextValidator
                fullWidth
                autoFocus
                name="reson"
                value={reasonInput}
                onChange={onChangeText}
                validators={selectedMenuIndex === 1 ? ["required"] : []}
                label={t("input.reason")}
                errorMessages={[t("validation.required")]}
                margin="normal"
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                onClick={handleDialog}
                color="inherit"
              >
                {t("buttons.close")}
              </Button>
              <Fab type="submit" variant="extended">
                {selectedMenuIndex === 1
                  ? t("buttons.reject")
                  : t("buttons.approve")}
              </Fab>
            </DialogActions>
          </ValidatorForm>
          <DialogContent>
            {selectedMenuIndex === 0 && (
              <FormControlLabel
                control={
                  <Checkbox onChange={onChangeCheckBox} color="primary" />
                }
                label="Don't show this message anymore"
              />
            )}
          </DialogContent>
        </Dialog>
    </React.Fragment>
  );
  //}
}

export default Leaves


