import React from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

const Footer = (props) => {
  return (
    <Grid container spacing={3} style={{ paddingTop: 40, paddingBottom: 20 }}>
      <Grid item md={12}>
        <div className="bottomMenu">
          <div className="bottomMenuItem" id={props.active && props.active === "home" ? "active" : ""}><Link to={{ pathname: "/home" }}><i className="fa fa-home"></i><br />Home</Link></div>
          <div className="bottomMenuItem"><Link to="/applyleave"><i className="fa fa-file-o"></i><br />Request</Link></div>
          <div className="bottomMenuItem"><Link to="#"><i className="fa fa-bell-o"></i><br />Notification</Link></div>
          <div className="bottomMenuItem" id={props.active && props.active === "profile" ? "active" : ""}><Link to={{ pathname: "/profile" }}><i className="fa fa-user-o"></i><br />Profile</Link></div>
        </div>
      </Grid>
    </Grid>
  );
};
export default Footer;
