import React, { Component } from "react";
import { storeUser, storeMaster } from "../../_reducers/reducer.user";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { LoginAPI } from "../../_api";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Button, Typography, Link, Grid } from "@material-ui/core";
import { LoadingIndicator2 } from "../../component";
import { toast } from "react-toastify";
import { t, setDefaultTranslations, setDefaultLanguage } from "../../_language";
import en from "../../_language/en.json";
import id from "../../_language/id.json";
import { TRANS_STRING, NAV_STRINGS } from "../../Const";
import { localCache } from "@prestasi/cache";
//import { nofetch as fetchJSON, sleep } from '@prestasi/nofetch'
import { GetClientIP } from "../../_helper/apiMethods";

//import  sessionCache  from "@prestasi/cache/sessionCache"
const { INPUT_REQ, INPUT_INVALID } = TRANS_STRING;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#5E35B1",
    },
    secondary: {
      main: "#F68E58",
      contrastText: "white"
    }
  }
});

class Login extends Component {
  getInitialData = () => ({
    isEmailView: false,
    isSigninView: true,
    logo: '',
    singinModel: {
      userID: "",
      password: ""
    }
  });

  constructor(props) {
    super(props);
    ValidatorForm.addValidationRule("isPasswordMatch", value => {
      let { partnerSignupModel } = this.state;
      if (value !== partnerSignupModel.password) {
        return false;
      }
      return true;
    });
    this.state = {
      ...this.getInitialData(),
      imgLogoUrl: localCache.getItem("sarvamlogo"),
      imgLogoWhiteUrl: localCache.getItem("logowhite"),
      ipAddress: localCache.getItem("ipaddress"),
    };
    setDefaultTranslations({ id, en });
    setDefaultLanguage("id");
    //localCache.removeItem("language");
    //localCache.removeItem("user");
    //localCache.removeItem("coremaster");

    if (localCache.getItem("user")) {
      this.props.storeUser(JSON.parse(localCache.getItem("user")));
      this.getMaster(JSON.parse(localCache.getItem("user")));
      this.props.history.push("/home")
    }

  }

  toDataURL(e) {
    let img = e.target
    var canvas = document.createElement('canvas');
    canvas.width = img.naturalWidth; // or 'width' if you want a special/scaled size
    canvas.height = img.naturalHeight; // or 'height' if you want a special/scaled size

    canvas.getContext('2d').drawImage(img, 0, 0);

    // ... or get as Data URI
    let base64Img = canvas.toDataURL('image/png')
    localCache.setItem(img.name, base64Img)
  }

  onSubmitPassword = async (e) => {
    await LoadingIndicator2.instance.showLoader();
    let { singinModel } = this.state;
    const data = new FormData(e.target);
    let t0 = performance.now()
    console.log("get ip address", this.state, localCache.getItem("ipaddress"))
    let response = await LoginAPI.Password(singinModel, data, localCache.getItem("ipaddress"));
    console.log("Api password finish in", performance.now() - t0)
    if (response.status !== '1') {
      localCache.setItem("user", JSON.stringify(response));
      localCache.setItem("language", "id");
      this.props.storeUser(response);
      this.getMaster(response);
      this.props.history.push("/home")
    } else {
      toast.error(response.message)
    }
    //} else {
    //  toast.error(login.message)
    //}

    LoadingIndicator2.instance.hideLoader();
  };

  getMaster = (res) => {
    this.setState({
      isEmailView: false
    });

  }

  onChangeSignIn = event => {
    let { name, value } = event.target;
    this.setState({
      singinModel: {
        ...this.state.singinModel,
        [name]: value
      }
    });
  };

  onChangeSignup = event => {
    let { name, value } = event.target;
    this.setState({
      univSignupModel: {
        ...this.state.partnerSignupModel,
        [name]: value
      }
    });
  };
  onResetClick = async (e) => {
    await LoadingIndicator2.instance.showLoader();
    let { singinModel } = this.state;
    let formData = new FormData();
    const data = new FormData(e.target);
    let login = await LoginAPI.Email(data);
    if (login.status !== '1') {
      formData.append('email', singinModel.userID);
      let response = await LoginAPI.Forget(login, formData);
      if (response.status === '1') {
        toast.error(response.message);
      } else {
        toast.success(t("buttons.resetSuccess"));
        this.setState({
          isForgetPassword: false,
          isSigninView: true
        });
      }
    }


    LoadingIndicator2.instance.hideLoader();
  };
  onForgetPasswordClick = () => {
    this.setState({
      isEmailView: true,
      isForgetPassword: true
    });
  };

  onClickSignup = () => {
    this.props.history.push(NAV_STRINGS.signup);
  };
  onClickLogo = () => {
    this.props.history.push("/");
  };

  componentDidMount() {
    GetClientIP(true) // get from cache
      .then(result => {
        this.setState({ ipAddress: result ? result.ip : '' })
      })
    GetClientIP() // get from network
      .then(result => {
        this.setState({ ipAddress: result.ip })
      })

    if (this.props.location.state && this.props.location.state.isLogout) { // if coming from logout
      LoginAPI.Remove(this.props.location.state.user)
    }
  }

  render() {

    let { isEmailView, singinModel, isForgetPassword, imgLogoUrl, imgLogoWhiteUrl } = this.state;
    //localCache.setItem("HW", "hello world")
    return (
      <MuiThemeProvider theme={theme}>
        <Grid
          container
          spacing={3}
          style={{ padding: 60 }}
        >
          <Grid item md={4}></Grid>
          <Grid item md={4}>
            <Grid
              container
              spacing={0}
              direction="column"
              justify="center"
            >
              <Grid item md={12} style={{ textAlign: 'center' }}>
                <img
                  src={imgLogoUrl || "assets/images/logo.png"}
                  style={{ width: '60%', marginBottom: 15 }}
                  alt="sarvam"
                  name="sarvamlogo"
                  onLoad={this.toDataURL}
                />
                <img
                  src={imgLogoWhiteUrl || "assets/images/logowhite.png"}
                  style={{ display: 'none' }}
                  alt="sarvam"
                  name="logowhite"
                  onLoad={this.toDataURL}
                />
              </Grid>
            </Grid>

            {isEmailView ? (
              <React.Fragment>
                {isForgetPassword ? (
                  <ValidatorForm onSubmit={this.onResetClick}>
                    <TextValidator
                      key="forgetemail"
                      fullWidth
                      autoFocus
                      value={singinModel.userID}
                      name="userID"
                      onChange={this.onChangeSignIn}
                      validators={["required", "isEmail"]}
                      label={t("buttons.username")}
                      errorMessages={[t(INPUT_REQ), t(INPUT_INVALID)]}
                      margin="normal"
                      variant="outlined"
                    />{" "}
                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      type="submit"
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        marginTop: "15px"
                      }}
                    >
                      {t("buttons.resetPassword")}
                    </Button>
                  </ValidatorForm>
                ) : (
                    // eslint-disable-next-line react/no-string-refs
                    <ValidatorForm ref="form" onSubmit={this.onSubmitPassword}>
                      <TextValidator
                        key="loginemail"
                        fullWidth
                        autoFocus
                        value={singinModel.userID}
                        name="userID"
                        onChange={this.onChangeSignIn}
                        validators={["required"]}
                        label={t("buttons.username")}
                        errorMessages={[t(INPUT_REQ)]}
                        margin="normal"
                        variant="outlined"
                        style={{ fontSize: 12 }}
                      />
                      <TextValidator
                        key="loginpassword"
                        fullWidth
                        value={singinModel.password}
                        name="password"
                        onChange={this.onChangeSignIn}
                        label={t("buttons.password")}
                        validators={["required"]}
                        errorMessages={[t(INPUT_REQ)]}
                        type="password"
                        margin="normal"
                        variant="outlined"
                        style={{ fontSize: 12 }}
                      />
                      <br />
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                          marginTop: "15px"
                        }}
                      >
                        {t("buttons.login")}
                      </Button>
                      <Typography style={{ margin: "10px" }}>
                        <Link
                          component="button"
                          variant="body2"
                          colour="primary"
                          onClick={this.onForgetPasswordClick}
                        >
                          {t("buttons.forgotPassword")}
                        </Link>
                      </Typography>
                    </ValidatorForm>
                  )}
              </React.Fragment>
            ) : (
                <ValidatorForm onSubmit={this.onSubmitPassword}>
                  <TextValidator
                    key="loginemail"
                    fullWidth
                    autoFocus
                    value={singinModel.userID}
                    name="userID"
                    onChange={this.onChangeSignIn}
                    validators={["required"]}
                    label={t("buttons.username")}
                    errorMessages={[t(INPUT_REQ)]}
                    margin="normal"
                    variant="outlined"
                    style={{ fontSize: 12 }}
                  />
                  <TextValidator
                    key="loginpassword"
                    fullWidth
                    value={singinModel.password}
                    name="password"
                    onChange={this.onChangeSignIn}
                    label={t("buttons.password")}
                    validators={["required"]}
                    errorMessages={[t(INPUT_REQ)]}
                    type="password"
                    margin="normal"
                    variant="outlined"
                    style={{ fontSize: 12 }}
                  />
                  <br />
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      marginTop: "15px"
                    }}
                  >
                    {t("buttons.login")}
                  </Button>
                  <Typography style={{ margin: "10px" }}>
                    <Link
                      component="button"
                      variant="body2"
                      colour="primary"
                      onClick={this.onForgetPasswordClick}
                    >
                      {t("buttons.forgotPassword")}
                    </Link>
                  </Typography>
                </ValidatorForm>
              )}
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>
      </MuiThemeProvider>
    );
  }
}


const mapDispatchToProps = dispatch => bindActionCreators({
  storeUser,
  storeMaster
}, dispatch)
const mapStateToProps = state => ({
  user: state.user.user
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)