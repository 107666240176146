export const CLEAR_NOTIFICATION = 'notification/clear'
export const CREATE_NOTIFICATION = 'notification/create'
export const DATA_CHANGED = 'datachanged'
export const UPDATE = 'update'
export const ERROR_NOTIFICATION = 'notification/error'

const initialState = {
    visible: false,
    title: '',
    message: '',
    dataChanged: false,
    userdata: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_NOTIFICATION:
            return {
                ...state,
                visible: true,
                title: action.title,
                message: action.message
            }
        case CLEAR_NOTIFICATION:
            return {
                ...state,
                visible: false,
            }
        case ERROR_NOTIFICATION:
            return {
                ...state,
                visible: true,
                title: action.title,
                message: action.message
            }
        case DATA_CHANGED:
            return {
                ...state,
                dataChanged: action.value
            }
        case UPDATE:
            return {
                ...state,
                userdata: action.userdata
            }
        default:
            return state
    }
}

export const updateComponent = (res) => {
    return dispatch => {
        dispatch({
            type: UPDATE,
            userdata: res
        })
        return setTimeout(() => {
            dispatch({
                type: UPDATE,
                userdata: {}
            })
        }, 500)
    }
}

export const dataChanged = () => {
    return dispatch => {
        dispatch({
            type: DATA_CHANGED,
            value: true
        })
        return setTimeout(() => {
            dispatch({
                type: DATA_CHANGED,
                value: false
            })
        }, 3000)
    }
}

export const createNotification = (title, message) => {
    return dispatch => {
        dispatch({
            type: CREATE_NOTIFICATION,
            title,
            message
        })
        return setTimeout(() => {
            dispatch({
                type: CLEAR_NOTIFICATION
            })
        }, 5000)
    }
}

export const clearNotification = () => {
    return dispatch => {
        dispatch({
            type: CLEAR_NOTIFICATION,
        })
    }
}
export const errorNotification = (message) => {
    return dispatch => {
        dispatch({
            type: ERROR_NOTIFICATION,
            title: 'Error',
            message
        })
        return setTimeout(() => {
            dispatch({
                type: CLEAR_NOTIFICATION
            })
        }, 5000)
    }
}