import { formDataConsole, DEV_URL, checkAPIError, generateHTTPRequest } from "../_helper/apiMethods";
import { devLog } from "../_helper/commonMethods";
import store from '../_helper/helper.store';
import { localCache } from "@prestasi/cache";
import moment from "moment";

export const Approve = (employeeUID, leaveRequestUID, remarks) => {
    let formData = new FormData();
    formData.append("remarks", remarks);
    formData.append("EmployeeUID", employeeUID);
    formData.append("LeaveRequestUID", leaveRequestUID);
  
    let url = "/api/hrms/leave/request/approve";
    return generateHTTPRequest(url, formData);
  };
  
  export const Reject = (employeeUID, leaveRequestUID, remarks) => {
    let formData = new FormData();
    formData.append("remarks", remarks);
    formData.append("EmployeeUID", employeeUID);
    formData.append("LeaveRequestUID", leaveRequestUID);
  
    let url = "/api/hrms/leave/request/reject";
    return generateHTTPRequest(url, formData);
  };
  
  export const ManagerList = () => {
    let formData = new FormData();
    let { user } = store.getState().user;
    formData.append("EmployeeUID", user.empUID);
    formData.append(
      "StartDate",
      moment()
        .subtract({ days: 7 })
        .toISOString()
    );
    formData.append("EndDate", moment().toISOString());
  
    let url = "/api/hrms/leave/request/manager/list";
    return generateHTTPRequest(url, formData);
  };
  
  export const ApprovalList = () => {
    let formData = new FormData();
    let { user } = store.getState().user;
  
    formData.append("LeaveStatus", "New");
    formData.append("EmployeeUID", user.empUID);
    formData.append(
      "StartDate",
      moment()
        .startOf("month")
        .toISOString()
    );
    formData.append(
      "EndDate",
      moment()
        .endOf("month")
        .toISOString()
    );
  
    let url = "/api/hrms/leave/request/approval/list";
    return generateHTTPRequest(url, formData);
  };

export const MasterList = (user, EmpUID, fromCache = false) => {
    let formData = new FormData();
    if (!user) {
        user = JSON.parse(localCache.getItem("user"))
    }
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmpUID', EmpUID ? EmpUID : '');
    formData.append('IsActive', true);
    formDataConsole(formData);
    let url = '/api/hrms/leave/master/list';
    if (fromCache) {
        console.log("MasterList Fetch from cache: ", url)
        return Promise.resolve(JSON.parse(localCache.getItem("/api/hrms/leave/master/list")))
    } else
        console.log("MasterList Fetch from network: ", url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            if (res.status !== '1') {
                localCache.setItem("/api/hrms/leave/master/list", JSON.stringify(res))
            }
            devLog("// leave master list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const RequestList = (user, empUID, StartDate, EndDate, fromCache = false) => {
    let formData = new FormData();
    if (!user) {
        user = JSON.parse(localCache.getItem("user"))
    }
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmployeeUID', empUID);
    formData.append('StartDate', StartDate);
    formData.append('EndDate', EndDate);
    let url = '/api/hrms/leave/request/list';
    formDataConsole(formData);
    devLog('url', url);
    if (fromCache) {
        console.log("RequestList Fetch from cache: ", url)
        return Promise.resolve(JSON.parse(localCache.getItem("/api/hrms/leave/request/list")))
    } else
        console.log("RequestList Fetch from network: ", url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            if (res.status !== '1')
                localCache.setItem("/api/hrms/leave/request/list", JSON.stringify(res))
            devLog("// leave request list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Add = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append("EmployeeUID", user.empUID);
    let url = '/api/hrms/leave/request';
    formDataConsole(formData);
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// Request add response", res)
            return res;
        })
        .catch(checkAPIError);
}