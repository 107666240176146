import React, { useEffect, useState } from "react";
import { Grid, Typography, AppBar, Toolbar } from "@material-ui/core";
//import { Link } from "react-router-dom";
import { Employee, CoreMaster } from "../_api";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RefreshIcon from '@material-ui/icons/Refresh';
import { capitalizeFirstLetter } from "../_helper/commonMethods";
import { LoadingIndicator } from "../component";
import { toast } from "react-toastify";
import { t } from "../_language";
import { useSelector } from 'react-redux'
import moment from "moment";

function EmployeeData(props) {
  const [myState, setMyState] = useState(
    {
      employeeStatus: "[Status]",
      employeeCode: "[Code]",
      employeeDetail: {},
      addressList: [],
      familyList: []
    }
  )

  const user = useSelector(state => state.user).user

  useEffect(() => {
    async function componentDidMount() {
      await LoadingIndicator.instance.showLoader();
      //let url = "../";

       Employee.Detail(user, null, null, null, true)
        .then(result => {
          if (result) {
            setMyState(state => ({
              ...state,
              employeeCode: result.employeeCode,
              employeeStatus: result.employeeStatus,
              employeeDetail: result
            }))
            if (result.photoUrl) {
              getBlobFromURL(result.photoUrl);
            }
          }
        })
      Employee.Detail(user)
        .then(result => {
          if (result.status !== "1") {
            setMyState(state => ({
              ...state,
              employeeCode: result.employeeCode,
              employeeStatus: result.employeeStatus,
              employeeDetail: result
            }))
            if (result.photoUrl) {
              getBlobFromURL(result.photoUrl);
            }
          } else {
            toast.error(result.message);
          }
        })
        Employee.DetailFamily(user, true)
        .then(result => {console.log("fam", result)
          if (result) {
            setMyState(state => ({
              ...state,
              familyList: result.familyList
            }))
          }
        })
      Employee.DetailFamily(user)
        .then(result => {
          if (result.status !== "1") {
            setMyState(state => ({
              ...state,
              familyList: result.familyList
            }))
          } else {
            toast.error(result.message);
          }
        })
      Employee.DetailAddress(user, true)
        .then(result => {console.log("add", result)
          if (result) {
            setMyState(state => ({
              ...state,
              addressList: result.addressList
            }))
          }
        })
      Employee.DetailAddress(user)
        .then(result => {
          if (result.status !== "1") {
            setMyState(state => ({
              ...state,
              addressList: result.addressList
            }))
          } else {
            toast.error(result.message);
          }
        })
    }
    const getBlobFromURL = async (blob) => {
      let url = '../';
      let res = await CoreMaster.AccessBlob(user, blob, url);
      if (res.status !== '1') {
        setMyState(state => ({
          ...state,
          photoUrl: res.message
        }))
      }
    }
    componentDidMount()
    .then(LoadingIndicator.instance.hideLoader())
  }, [user])

  let { employeeCode, employeeDetail, addressList, familyList } = myState
  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <ArrowBackIcon style={{ color: '#fff', paddingRight: 15 }} onClick={() => props.history.push("/profile")}></ArrowBackIcon>
          <Typography variant="h6">{t("buttons.employeeData")}</Typography>
          <RefreshIcon style={{ color: '#fff', position: 'absolute', right: 15 }} onClick={() => window.location.reload()}></RefreshIcon>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: 56 }}>
        <Grid item md={12} style={{ textAlign: 'left', width: '100%', paddingLeft: 15, paddingTop: 15, paddingBottom: 15, zIndex: 99 }}>
          <Typography variant="h6" style={{ color: "#000", fontWeight: "bold" }}>{capitalizeFirstLetter(user.empName)}</Typography>
          <Typography variant="body1"><span style={{ fontSize: "12px", fontWeight: "bold", color: "#bbbbbd" }}>{employeeCode}</span></Typography>
        </Grid>
        <Grid container spacing={16} className="employeeData">
          <Grid item xs={6}>{t("buttons.jobTitle")}</Grid>
          <Grid item xs={6}>:&nbsp;&nbsp;{employeeDetail.positionName || '--'}</Grid>
          <Grid item xs={6}>{t("buttons.organization")}</Grid>
          <Grid item xs={6}>:&nbsp;&nbsp;{employeeDetail.orgUnitName || '--'}</Grid>
          <Grid item xs={6}>{t("buttons.department")}</Grid>
          <Grid item xs={6}>:&nbsp;&nbsp;{employeeDetail.departmentName || '--'}</Grid>
          <Grid item xs={6}>{t("buttons.location")}</Grid>
          <Grid item xs={6}>:&nbsp;&nbsp;{employeeDetail.departmentName || '--'}</Grid>
          <Grid item xs={6}>{t("buttons.joinDate")}</Grid>
          <Grid item xs={6}>:&nbsp;&nbsp;{moment(employeeDetail.joinDate).format("DD-MM-YYYY") || '--'}</Grid>
          <Grid item xs={6}>{t("buttons.title")}</Grid>
          <Grid item xs={6}>:&nbsp;&nbsp;{employeeDetail.salutation || '--'}</Grid>
          <Grid item xs={6}>{t("buttons.sex")}</Grid>
          <Grid item xs={6}>:&nbsp;&nbsp;{employeeDetail.gender || '--'}</Grid>
          <Grid item xs={6}>{t("buttons.born")}</Grid>
          <Grid item xs={6}>:&nbsp;&nbsp;{(employeeDetail.birthPlace || '--') + ', ' + (moment(employeeDetail.birthDate).format("DD-MM-YYYY") || '--')}</Grid>
          <Grid item xs={6}>{t("buttons.citizenship")}</Grid>
          <Grid item xs={6}>:&nbsp;&nbsp;{employeeDetail.nationality || '--'}</Grid>
          <Grid item xs={6}>{t("buttons.maritalStatus")}</Grid>
          <Grid item xs={6}>:&nbsp;&nbsp;{employeeDetail.maritalStatus || '--'}</Grid>
          <Grid item xs={6}>{t("buttons.religion")}</Grid>
          <Grid item xs={6}>:&nbsp;&nbsp;{employeeDetail.religion || '--'}</Grid>
          <Grid item xs={6}>{t("buttons.bloodType")}</Grid>
          <Grid item xs={6}>:&nbsp;&nbsp;{employeeDetail.bloodType || '--'}</Grid>
        </Grid>
        <Grid container spacing={16} className="employeeData">
          <Grid item xs={12}><Typography variant="h6" style={{ color: "#000", fontWeight: "bold", marginBottom: 10 }}>{t("buttons.contactInformation")}</Typography></Grid>
          <Grid item xs={5}>{t("buttons.mobilePhone")}</Grid>
          <Grid item xs={7}>:&nbsp;&nbsp;{employeeDetail.mobileNo || '--'}</Grid>
          <Grid item xs={5}>{t("buttons.email")}</Grid>
          <Grid item xs={7}>:&nbsp;&nbsp;{employeeDetail.email || '--'}</Grid>
          <Grid item xs={5}>{t("buttons.address")}</Grid>
          <Grid item xs={7}>:
          {addressList.map((add, index) => (
            <span key={index}>&nbsp;&nbsp;&bull;&nbsp;&nbsp;{add.address || '--'}<br /></span>
          ))}
          </Grid>
        </Grid>
        <Grid container spacing={16} className="employeeData">
          <Typography variant="h6" style={{ color: "#000", fontWeight: "bold" }}>{t("buttons.family")}</Typography>
          <div className="family">
            <div style={{ margin: '10px 0' }}>
              {familyList.map((emp, index) => (
                <div className="historydetail" key={emp.uid}>
                  <div className="col">
                    <div><span>{emp.familyName}</span> <span className="float-right text-black">{capitalizeFirstLetter(emp.relation)}</span></div>
                    <div><span style={{ color: '#cccccc' }}>{emp.address}</span></div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </div>
      <div className="clearfix"></div>
    </React.Fragment>
  );
}

export default EmployeeData