import { formDataConsole, DEV_URL, checkAPIError } from "../_helper/apiMethods";
import { devLog } from "../_helper/commonMethods";
import store from '../_helper/helper.store';
import { localCache } from "@prestasi/cache";
//import { nofetch as fetchJSON } from "@prestasi/nofetch";

export const List = (user, uid, lastFetchDate, fromCache = false) => {
    let { language: { ipaddress } } = store.getState();
    let formData = new FormData();
    let listName = 'color,HolidayList,employeestatus,orgunit,division,WorkReasonType,CoreModule,City,Country,Years of Work Exp,Job Function,Industry,ID Card Type,rmscandidatefields,gender,marital,salutation,CreditFrequency,Timezone,Download APK,Relation,EducationDegree,Religion,BloodType,Province,LocationType,AwardType,DisciplinaryType,ActionType,TerminationType';
    if (!user) {
        user = JSON.parse(localCache.getItem("user"))
    }
    formData.append('lastfetchdate', lastFetchDate);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('ListName', listName);
    formData.append('IsActive', true);
    formDataConsole(formData);
    let url;
    if (uid) {
        url = '/api/hrms/master/list';
    } else {
        url = '/api/core/master/list';
    }
    devLog('url', url)

    if (fromCache) {
        console.log("from cache list")
        return Promise.resolve(JSON.parse(localCache.getItem("coremaster")))
    }

    console.log("from list")
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            if (res.status !== '1') {
                localCache.setItem("coremaster", JSON.stringify(res));
            }
            devLog("// master list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Add = (user, formData) => {
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formDataConsole(formData);
    let url;
    url = '/api/hrms/master/add';
    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// master add list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (user, formData) => {
    //let formData = new FormData();  
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formDataConsole(formData);
    let url;
    url = '/api/hrms/master/update';
    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// master update list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const AccessBlob = (user, blob, directory) => {
    let { language: { ipaddress } } = store.getState();
    let url = '/api/core/accessbloburl';
    let formData = new FormData();
    formData.append('BlobURL', blob);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formDataConsole(formData);
    devLog('url', url)
    //return fetch(directory.concat(DEV_URL, url), {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// blob url response", res)
            return res;
        })
        .catch(checkAPIError);
}