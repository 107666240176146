import React from "react";
import { Typography, Paper } from "@material-ui/core";

const PageTitle = ({ title, iconData = [] }) => (
  <Paper
    elevation={0}
    style={{ padding: "15px 20px", alignItems: "center", display: "flex" }}
  >
    <Typography variant="subtitle2" style={{ flex: 1 }}>
      {title}
    </Typography>
    {iconData.map((iconObject, index) => iconObject.icon)}
  </Paper>
);
export default PageTitle;
