import { combineReducers } from "redux";
import user from "./reducer.user";
import master from "./reducer.master";
import language from "./reducer.language";

export default combineReducers({
  user,
  master,
  language
});
