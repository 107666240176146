import React, { useEffect, useState } from "react";
import { Typography, AppBar, Toolbar, Grid, IconButton, Paper } from "@material-ui/core";
import { KeyboardArrowLeft, ArrowBack, KeyboardArrowRight } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { LoadingIndicator } from "../component";
import { t } from "../_language";
import { useSelector } from 'react-redux';
import { AttendenceAPI } from "../_api";
import Chart from "chart.js";
import { Colors, STORAGE_NAMES } from "../Const";
import moment from "moment";
import { sessionCache, localCache } from "@prestasi/cache";

/*const muiStyle = makeStyles({
  buttonLabel: {
    color: "white"
  }
});*/
function SelfService(props) {
  /*const [myState, setMyState] = useState({
    employeeStatus: "[Status]",
    employeeCode: "[Code]"
  })*/
  //const classes = muiStyle();
  const [isServiceManager] = useState(false);
  const user = useSelector(state => state.user).user
  const [myState, setMyState] = useState({
    imgAttendanceUrl: '',
    imgLeaveUrl: ''
  })

  const toDataURL = (e) => {
    let img = e.target
    var canvas = document.createElement('canvas');
    canvas.width = img.naturalWidth; // or 'width' if you want a special/scaled size
    canvas.height = img.naturalHeight; // or 'height' if you want a special/scaled size

    canvas.getContext('2d').drawImage(img, 0, 0);

    // ... or get as Data URI
    let base64Img = canvas.toDataURL('image/png')
    localCache.setItem(img.name, base64Img)
  }
  useEffect(() => {
    async function componentDidMount() {
      await LoadingIndicator.instance.showLoader();
      //let { user } = this.props;
      setMyState(state => ({
        ...state,
        imgAttendanceUrl: localCache.getItem("imgattendance"),
        imgLeaveUrl: localCache.getItem("imgleave")
      }))
      LoadingIndicator.instance.hideLoader();
    }
    componentDidMount();
  }, [user])

  //const onClickSSM = () => setServiceManager(!isServiceManager);
  let { imgAttendanceUrl, imgLeaveUrl } = myState;
  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar>
        <ArrowBack style={{ color: "#fff", paddingRight: 15 }} onClick={() => props.history.push("/home")}></ArrowBack>
          <Typography variant="h6">{t("buttons.selfService")}</Typography>
          {/*!isServiceManager && user.numOfStaff > 0 && (
            <Button
              onClick={onClickSSM}
              classes={{
                label: classes.buttonLabel
              }}
              variant="text"
            >
              <Typography style={{ flexGrow: 1 }}>{t("buttons.selfServiceManager")}</Typography>
              <ArrowForward style={{ color: "#fff", paddingLeft: 15 }} />
            </Button>
            )*/}
        </Toolbar>
      </AppBar>
      <div className="service" style={{ marginTop: 70 }}>
        <div className="selfMenu">
          <div className="topMenuItem"><Link to={{ pathname: "/attendance", state: { isServiceManager: !isServiceManager && user.numOfStaff > 0 ? true : false } }}><div className="boxImg"><img src={imgAttendanceUrl || "/assets/images/attendance.png"} alt="Attendance" style={{ height: 30 }} name="imgattendance" onLoad={toDataURL} /></div>{t("buttons.attendance")}</Link></div>
          <div className="topMenuItem"><Link to={{ pathname: "/leave", state: { isServiceManager: !isServiceManager && user.numOfStaff > 0 ? true : false } }}><div className="boxImg"><img src={imgLeaveUrl || "/assets/images/leave.png"} alt="Leave" style={{ height: 30 }} name="imgleave" onLoad={toDataURL} /></div>{t("buttons.leaves")}</Link></div>
        </div>
        {/*<div className="col">
          <div className="item">
            <Link to={{ pathname: "/attendance", state: { isServiceManager: !isServiceManager && user.numOfStaff > 0 ? true : false } }}>
              <div className="circle"><i className="fa fa-home"></i></div>
              <div>{t("buttons.attendance")}</div>
            </Link>
          </div>
        </div>
        <div className="col">
          <div className="item">
            <Link to={{ pathname: "/leave", state: { isServiceManager: !isServiceManager && user.numOfStaff > 0 ? true : false } }}>
              <div className="circle"></div>
              <div>{t("buttons.leaves")}</div>
            </Link>
          </div>
        </div>
        <div className="col">
            <div className="item">
              <Link to={{ pathname: "/" }}>
                <div className="circle"></div>
                {t("buttons.reimbursement")}
              </Link>  
            </div> 
          </div>
          <div className="col">
            <div className="item">
              <Link to={{ pathname: "/" }}>
                <div className="circle"></div>
                {t("buttons.payslip")}
              </Link> 
            </div> 
          </div>
          <div className="col">
            <div className="item">
              <Link to={{ pathname: "/" }}>
                <div className="circle"></div>
                {t("buttons.tax")}
              </Link> 
            </div> 
          </div>
          <div className="col">
            <div className="item">
              <Link to={{ pathname: "/" }}>
                <div className="circle"></div>
                {t("buttons.officeAsset")}
              </Link> 
            </div> 
          </div>
          <div className="col">
            <div className="item">
              <Link to={{ pathname: "/" }}>
                <div className="circle"></div>
                {t("buttons.carpool")}
              </Link> 
            </div> 
          </div>*/}
      </div>
      <div className="clearfix"></div>
      {/*<div className="history">
          <p className="text-large text-bold">{t("buttons.attendanceThisMonth")}</p>
        </div>     */}
      <AttendanceChart />
    </React.Fragment>
  );
}

export default SelfService;

class AttendanceChart extends React.PureComponent {
  state = {
    lineData: [],
    barData: [],
    labels: [],
    weekNo: 1
  };
  formattedData = [];
  totalWeeks = 0;

  async componentDidMount() {
    let cache = sessionCache.getItem(STORAGE_NAMES.attendanceThisMonth);
    let response;
    var today = moment();

    if (cache) {
      response = JSON.parse(cache);
      this.totalWeeks = response.totalWeeks;
      this.thisMonth = response.thisMonth;
      this.formattedData = response.formattedData;
    } else {
      response = await AttendenceAPI.EmployeeList();
      if (!response) return;

      let week = 0;
      var startOfmonth = moment().startOf("month");
      let days = today.diff(startOfmonth, "days");
      let workingTime = 0;

      for (let index = 0; index < days; index++) {
        let monthDate = moment()
          .startOf("month")
          .add(index, "days");
        let fItem = response.find(item => {
          let attendanceDate = moment(item.attendanceDate.replace("T", " "));
          if (attendanceDate.date() === monthDate.date()) return true;
          return false;
        });
        let actualTime = 0;
        let framedData;
        let day = monthDate.format("ddd");
        let date = monthDate.get("date");
        let label = `${day} ${date}`;

        week = this.getWeekNo(monthDate.date());
        if (fItem) {
          let { checkIn, checkOut, shiftStartTime, shiftEndTime } = fItem;
          if (checkIn && checkOut) {
            let mcIn = moment(checkIn.replace("T", " "));
            let mcOut = moment(checkOut.replace("T", " "));
            actualTime = mcOut.diff(mcIn, "hour");
          }
          if (shiftStartTime && shiftEndTime) {
            let shiftStart = moment(shiftStartTime.replace("T", " "));
            let shiftEnd = moment(shiftEndTime.replace("T", " "));
            let shiftDiff = shiftEnd.diff(shiftStart, "hour");
            if (shiftDiff > workingTime) workingTime = shiftDiff;
          }

          framedData = {
            actualTime,
            workingTime,
            label,
            weekNo: week
          };
        } else {
          framedData = {
            actualTime,
            workingTime,
            label,
            weekNo: week
          };
        }
        this.formattedData.push(framedData);
      }
      this.totalWeeks = week;
      this.thisMonth = startOfmonth.format("MMM YYYY");
      sessionCache.setItem(
        STORAGE_NAMES.attendanceThisMonth,
        JSON.stringify({
          totalWeeks: week,
          thisMonth: this.thisMonth,
          formattedData: this.formattedData
        })
      );
    }
    let currentWeek = this.getWeekNo(today.date());
    this.setState(
      {
        weekNo: currentWeek,
        ...this.generateChartData(currentWeek)
      },
      this.bindChartData
    );
  }

  generateChartData = weekNo => {
    let labels = [];
    let lineData = [];
    let barData = [];
    this.formattedData
      .filter(item => item.weekNo === weekNo)
      .map(item => {
        labels.push(item.label);
        lineData.push(item.workingTime);
        barData.push(item.actualTime);
        return item;
      });
    var max = Math.max(...lineData);
    lineData = lineData.map(() => max);
    return { labels, lineData, barData };
  };

  getWeekNo = date => {
    return Math.ceil(date / 7);
  };

  bindChartData = () => {
    let { lineData, barData, labels } = this.state;
    if (this.chartCtx) {
      let {
        config: { data }
      } = this.chartCtx;
      data.datasets[0].data = lineData;
      data.datasets[1].data = barData;
      data.labels = labels;
      this.chartCtx.update({});
      return;
    }
    var ctx = document.getElementById("myChart");
    this.chartCtx = new Chart(ctx, {
      type: "line",
      data: {
        datasets: [
          {
            data: lineData,
            fill: false
          },
          {
            type: "bar",
            showLine: false,
            data: barData,
            backgroundColor: Colors.buttonPrimary
          }
        ],
        labels: labels
      },
      options: {
        legend: false,
        responsive: true
      }
    });
  };

  onClickNextWeek = shouldAdd => () => {
    var week = this.state.weekNo;
    if (shouldAdd) week++;
    else week--;
    this.setState(
      {
        weekNo: week,
        ...this.generateChartData(week)
      },
      this.bindChartData
    );
  };

  render() {
    let { weekNo } = this.state;
    return (
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{
            paddingLeft: 15
          }}
        >
          <Typography variant="h6">
            {t("buttons.attendanceThisMonth")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            padding: 15
          }}
        >
          <Paper>
            <Grid item container alignItems="center">
              <Grid item xs>
                <Typography variant="body2">{this.thisMonth}</Typography>
              </Grid>
              <Grid item>
                <Grid container justify="flex-end" alignItems="center">
                  <IconButton
                    disabled={weekNo === 1}
                    onClick={this.onClickNextWeek(false)}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                  <Typography>W{weekNo}</Typography>
                  <IconButton
                    onClick={this.onClickNextWeek(true)}
                    disabled={weekNo >= this.totalWeeks}
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <canvas id="myChart" height={100} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}