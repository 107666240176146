import { formDataConsole, DEV_URL, checkAPIError } from "../_helper/apiMethods";
import { devLog } from "../_helper/commonMethods";
import store from '../_helper/helper.store';
import { localCache } from "@prestasi/cache";
//import { nofetch as fetchJSON } from "@prestasi/nofetch";

export const List = (user) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('DirectManagerUID', user.empUID);
    formDataConsole(formData);
    let url = '/api/hrms/attendance/schedule/list';
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// schedule list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Checkin = (formData, user, attendanceUID) => {
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmployeeUID', user.empUID);
    formData.append('UID', attendanceUID);
    formData.append('ClientType', 'Web');
    formData.append('SSID', null);
    formDataConsole(formData);
    let url = '/api/hrms/attendance/2/checkin';
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// checkin response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const CheckInDetail = (user, EmployeeUID, fromCache = false, lat, lang) => {
    let { language: { ipaddress } } = store.getState();
    let formData = new FormData();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmployeeUID', EmployeeUID);
    formData.append('Latitude', '');
    formData.append('Longitude', '');
    let url = '/api/hrms/attendance/checkin/detail';
    formDataConsole(formData);
    devLog('url', url);
    if (fromCache) {
        console.log("CheckinDetail from cache")
        return Promise.resolve(JSON.parse(localCache.getItem("checkindetail")))
    }
    console.log("CheckinDetail from network")
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// checkin detail response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const CheckOutDetail = (user, EmployeeUID, fromCache = false, lat, lang) => {
    let { language: { ipaddress } } = store.getState();
    let formData = new FormData();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmployeeUID', EmployeeUID);
    formData.append('Longitude', '');
    formData.append('Latitude', '');
    let url = '/api/hrms/attendance/checkout/detail';
    formDataConsole(formData);
    devLog('url', url);
    if (fromCache) {
        console.log("CheckoutDetail from cache")
        return Promise.resolve(JSON.parse(localCache.getItem("checkoutdetail")))
    }
    console.log("CheckoutDetail from network")
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// checkin detail response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Checkout = (formData, user, attendanceUID) => {
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmployeeUID', user.empUID);
    formData.append('UID', attendanceUID);
    formData.append('ClientType', 'Web');
    formData.append('SSID', null);
    formDataConsole(formData);
    let url = '/api/hrms/attendance/2/checkout';
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// checkin response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const CheckAtt = (user, attendanceUID) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmployeeUID', user.empUID);
    formData.append('UID', attendanceUID);
    let url = '/api/hrms/attendance/checkatt';
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// checkatt response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const CheckinNoshift = (formData, user, AttendanceDate) => {
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmployeeUID', user.empUID);
    formData.append('AttendanceDate', AttendanceDate);
    formData.append('ClientType', 'Web');
    formData.append('SSID', null);
    formDataConsole(formData);
    let url = '/api/hrms/attendance/noshift/checkin';
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// checkin noshift response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const CheckoutNoshift = (formData, user, AttendanceUID) => {
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmployeeUID', user.empUID);
    formData.append('AttendanceUID', AttendanceUID);
    formData.append('ClientType', 'Web');
    formData.append('SSID', null);
    formDataConsole(formData);
    let url = '/api/hrms/attendance/noshift/checkout';
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// checkout noshift response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const AttendanceNoshift = (user, startDate, endDate, fromCache = false) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmployeeUID', user.empUID);
    formData.append('StartDate', startDate);
    formData.append('EndDate', endDate);

    //formDataConsole(formData);

    //let url = '/api/hrms/attendance/noshift/attendance';
    let url = '/api/hrms/attendance/schedule/employee/list';

    if (fromCache) {
        console.log("Attendance from cache")
        return Promise.resolve(JSON.parse(localCache.getItem("/api/hrms/attendance/schedule/employee/list")))
    }
    console.log("Attendance from network")
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// attendance response", res)
            localCache.setItem("/api/hrms/attendance/schedule/employee/list", JSON.stringify(res))
            return res;
        })
        .catch(checkAPIError);
}

export const CheckChekin = (user, fromCache = false) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', localCache.getItem("ipaddress") ? localCache.getItem("ipaddress") : ipaddress);
    formData.append('EmployeeUID', user.empUID);

    formDataConsole(formData);

    //let url = '/api/hrms/attendance/noshift/checkcheckin';
    let url = '/api/hrms/attendance/checkcheckin';

    //devLog('url', url);

    if (fromCache) {
        console.log("from cache CheckChekin")
        return Promise.resolve(JSON.parse(localCache.getItem("/api/hrms/attendance/checkcheckin")))
    }
    console.log("from CheckChekin")
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// check checkin response", res)
            localCache.setItem("/api/hrms/attendance/checkcheckin", JSON.stringify(res))
            return res;
        })
        .catch(checkAPIError);
}