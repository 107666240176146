import React from "react";
import { Route, Switch } from "react-router-dom";
import { setDefaultTranslations, setDefaultLanguage } from "./_language";
import en from "./_language/en.json";
import id from "./_language/id.json";
import { Login, HomePage, Attendance, AttendanceDetail, Profile, EmployeeData, SelfService, Leaves, ApplyLeave, Password } from "./pages";
import { ToastContainer } from "react-toastify";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "./styles/Theme";
import { LoadingIndicator, LoadingIndicator2, PrivateRoute } from "./component";
//import { NAV_STRINGS } from "./Const";

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    setDefaultTranslations({ id, en });
    setDefaultLanguage("id");    
  }
  setLoaderInstance = ref => (LoadingIndicator.instance = ref);
  setLoaderInstance2 = ref => (LoadingIndicator2.instance = ref);
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <ToastContainer />
        <LoadingIndicator ref={this.setLoaderInstance} />
        <LoadingIndicator2 ref={this.setLoaderInstance2} />
        <Switch>
          <Route exact path="/" component={Login} />
          <PrivateRoute exact path="/home" component={HomePage} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/attendance" component={Attendance} />
          <PrivateRoute exact path="/attendancedetail" component={AttendanceDetail} />
          <PrivateRoute exact path="/employeedata" component={EmployeeData} />
          <PrivateRoute exact path="/selfservice" component={SelfService} />
          <PrivateRoute exact path="/leave" component={Leaves} />
          <PrivateRoute exact path="/applyleave" component={ApplyLeave} />
          <PrivateRoute exact path="/changepassword" component={Password} />
        </Switch>
      </MuiThemeProvider>
    );
  }
}
export default App;
