import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../_reducers";
import * as GoogleAnalytics from "./helper.googleAnalytics";
import { updatePageTitile } from "./commonMethods";
import { SESS_STORAGE_KEY } from "./apiMethods";
import { createBrowserHistory } from "history";
export const history = createBrowserHistory();
var initialState = {};

if (sessionStorage.getItem(SESS_STORAGE_KEY)) {
  initialState = JSON.parse(sessionStorage.getItem(SESS_STORAGE_KEY));
}

const enhancers = [];
GoogleAnalytics.initReactGA(GoogleAnalytics.DEFAULT_CONFIG);
const middleware = [thunk];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancer(applyMiddleware(...middleware), ...enhancers)
);

store.subscribe(() => {
  sessionStorage.setItem(SESS_STORAGE_KEY, JSON.stringify(store.getState()));
});

if (module.hot) {
  module.hot.accept("../_reducers", () => {
    store.replaceReducer(rootReducer);
  });
}
history.listen((locaiton, action) => {
  let { pathname } = locaiton;
  GoogleAnalytics.TrackPageHelper(pathname);
  updatePageTitile(pathname);
});
export default store;
