import React, { useEffect, useState } from "react";
import { Footer } from "../component";
import { Grid, Typography, AppBar, Toolbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Employee, CoreMaster } from "../_api";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RefreshIcon from '@material-ui/icons/Refresh';
import { capitalizeFirstLetter } from "../_helper/commonMethods";
import { LoadingIndicator } from "../component";
import { toast } from "react-toastify";
import { useSelector } from 'react-redux'
import { t } from "../_language";
import { localCache } from "@prestasi/cache";

function Profile(props) {
  const [myState, setMyState] = useState({
    employeeStatus: "[Status]",
    employeeCode: "[Code]",
    photoUrl: '/assets/images/user.png'
  })

  //get state from store
  const user = useSelector(state => state.user).user

  useEffect(() => {
    async function componentDidMount() {
      await LoadingIndicator.instance.showLoader();
      //let url = "../";

      //let res = 
      Employee.Detail(user, null, null, null, true) // from cache
        .then(result => {
          if (result) {
            setMyState(state => ({
              ...state,
              employeeCode: result.employeeCode,
              employeeStatus: result.employeeStatus
            }))
          }

        }).then(LoadingIndicator.instance.hideLoader())

      Employee.Detail(user) // from network
        .then(result => {
          if (result.status !== "1") {
            setMyState(state => ({
              ...state,
              employeeCode: result.employeeCode,
              employeeStatus: result.employeeStatus
            }))
          } else {
            toast.error(result.message);
          }
        }).then(LoadingIndicator.instance.hideLoader())

      const getBlobFromURL = async (blob) => {
        let url = '../';
        let res = await CoreMaster.AccessBlob(user, blob, url);
        if (res.status !== '1') {
          setMyState(state => ({
            ...state,
            photoUrl: res.message
          }))
        }
        LoadingIndicator.instance.hideLoader();
      }

      if (user.photoURL) {
        getBlobFromURL(user.photoURL);
      }
    }

    componentDidMount();
  }, [user])

  const SignOut = () => {
    localCache.removeItem("language");
    localCache.removeItem("user");
    localCache.removeItem("coremaster");
    localCache.removeItem("photourl");
    props.history.push("/", { isLogout: true, user })
  }

  let defaultimage = "/assets/images/user.png";
  let { photoUrl, employeeStatus, employeeCode } = myState;
  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar>
          <ArrowBackIcon style={{ color: '#fff', paddingRight: 15 }} onClick={() => props.history.push("/home")}></ArrowBackIcon>
          <Typography variant="h6">{t("buttons.myProfile")}</Typography>
          <RefreshIcon style={{ color: '#fff', position: 'absolute', right: 15 }} onClick={() => window.location.reload()}></RefreshIcon>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: 56 }}>
        <Grid item md={12} style={{ textAlign: 'left', paddingLeft: 120, width: '100%', paddingTop: 15, paddingBottom: 15, zIndex: 99, backgroundColor: "#f5f8ff" }}>
          <Typography variant="h6" style={{ color: "#000", fontWeight: "bold" }}>{capitalizeFirstLetter(user.empName)}</Typography>
          <Typography variant="body1"><span >{employeeStatus}</span><br /><span style={{ fontSize: 12, fontWeight: "bold", color: "#bbbbbd" }}>{employeeCode}</span></Typography>
        </Grid>
        <Grid item md={12}>
          <img
            alt='user'
            style={{ borderRadius: '50%', width: 80, height: 80, display: 'inline-block', position: 'absolute', left: '15px', top: '70px', border: '5px solid #fff', zIndex: 99 }}
            src={photoUrl || defaultimage} />
          <div className="clearfix"></div>
        </Grid>
      </div>
      <div className="clearfix"></div>
      <Grid item md={12} style={{ textAlign: 'left', width: '100%', zIndex: 99, backgroundColor: "#fff" }}>
        <ul className="menumenu">
          <li><Link to={{ pathname: "/employeedata" }}>{t("buttons.employeeData")}
            <span className="pull-right-container">
              <i className="fa fa-angle-right pull-right"></i>
            </span></Link></li>
          {/*<li><Link to={{ pathname: "#" }}>{t("buttons.compensation")}
            <span className="pull-right-container">
              <i className="fa fa-angle-right pull-right"></i>
            </span></Link></li>
          <li><Link to={{ pathname: "#" }}>{t("buttons.history")}
            <span className="pull-right-container">
              <i className="fa fa-angle-right pull-right"></i>
          </span></Link></li>*/}
          <li><Link to={{ pathname: "/changepassword" }}>{t("buttons.changePassword")}</Link></li>
          <li onClick={SignOut} style={{ color: "red" }}>{t("buttons.signout")}
          </li>
        </ul>
      </Grid>
      <Footer active="profile" />
    </React.Fragment>
  );
}

export default Profile;